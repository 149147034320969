import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import { ToastProvider } from './context/ToastContext';
import * as serviceWorker from './serviceWorker';

// Debug environment variables
console.log('Environment Variables at Startup:');
console.log('NODE_ENV:', process.env.NODE_ENV);
console.log('OpenAI Key exists:', !!process.env.REACT_APP_OPENAI_API_KEY);
console.log('OpenAI Key prefix:', process.env.REACT_APP_OPENAI_API_KEY?.substring(0, 7));

// Remove the problematic code that requires a missing package
// if (process.env.NODE_ENV === 'development') {
//   const { whyDidYouUpdate } = require('why-did-you-update');
//   whyDidYouUpdate(React);
// }

// Create function to clear API cache when needed (e.g., after logout)
window.clearApiCache = () => {
  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      action: 'clearApiCache'
    });
    console.log('Sent message to clear API cache');
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // Remove StrictMode to prevent double mounting in development
  // <React.StrictMode>
    <AuthProvider>
      <ToastProvider>
        <App />
      </ToastProvider>
    </AuthProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();