import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar.jsx';
import Footer from './components/Footer';
import { useAuth, AuthProvider } from './context/AuthContext';
import { ToastProvider } from './context/ToastContext';
import { ProjectProvider } from './context/ProjectContext';
import { debugToken, attemptTokenRepair } from './utils/authDebug';
import ErrorBoundary from './components/ErrorBoundary';
import { ErrorHandler, ErrorTypes } from './utils/errorHandler';

// Replace regular imports with lazy ones
const Home = lazy(() => import('./pages/Home'));
const DemoPage = lazy(() => import('./pages/DemoPage'));
const TablePage = lazy(() => import('./pages/TablePage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const Program = lazy(() => import('./pages/userpages/ProgramPage'));
const UserHomePage = lazy(() => import('./pages/userpages/UserHome'));
const AllClaimsPage = lazy(() => import('./pages/userpages/AllClaimsPage'));
const ActivityPage = lazy(() => import('./pages/userpages/ActivityPage'));

// Simple 404 component
const NotFound = () => (
  <div style={{padding: "40px", textAlign: "center"}}>
    <h1>Page Not Found</h1>
    <p>The page you are looking for doesn't exist.</p>
    <Link to="/">Return to Home</Link>
  </div>
);

// Error fallback component
const ErrorFallback = ({ error, resetError }) => (
  <div style={{padding: "40px", textAlign: "center", color: "#d32f2f"}}>
    <h1>Something went wrong</h1>
    <p>{error?.userMessage || error?.message || 'An unexpected error occurred'}</p>
    <button 
      onClick={resetError}
      style={{
        padding: '8px 16px',
        background: '#2196f3',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer'
      }}
    >
      Try Again
    </button>
  </div>
);

// Loading fallback component
const LoadingFallback = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%'
  }}>
    <div style={{
      textAlign: 'center',
      padding: '20px',
      borderRadius: '8px',
      backgroundColor: '#f5f5f5'
    }}>
      <div>Loading...</div>
      <div style={{
        marginTop: '10px',
        width: '50px',
        height: '50px',
        border: '5px solid #f3f3f3',
        borderTop: '5px solid #3498db',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
        margin: '0 auto'
      }}></div>
    </div>
  </div>
);

// Protected Route component with improved auth checking
const ProtectedRoute = ({ children }) => {
  const { user, refreshToken, loginWithDemoCredentials } = useAuth();
  
  useEffect(() => {
    // Check and repair token on mount
    if (!user) {
      const { hasToken } = debugToken();
      if (hasToken) {
        // Try to repair token format if needed
        const repaired = attemptTokenRepair();
        if (repaired) {
          // Refresh authentication
          refreshToken();
        }
      }
    }
  }, [user, refreshToken]);
  
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return (
    <ErrorBoundary 
      refreshToken={refreshToken}
      loginWithDemoCredentials={loginWithDemoCredentials}
      fallback={(error, resetError) => (
        <ErrorFallback error={error} resetError={resetError} />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

function AppContent() {
  const { refreshToken, loginWithDemoCredentials } = useAuth();
  
  useEffect(() => {
    // Run auth debugging on application mount
    const { hasToken, hasUser } = debugToken();
    
    // Attempt to repair token if needed
    if (hasToken && !hasUser) {
      attemptTokenRepair();
      refreshToken();
    }
    
    // Setup API error monitoring
    const originalFetch = window.fetch;
    window.fetch = async (...args) => {
      try {
        const response = await originalFetch(...args);
        
        // Check for auth errors
        if (response.status === 401 || response.status === 403) {
          console.warn('Authentication error detected in API call');
          
          // Try to repair the token
          const wasRepaired = attemptTokenRepair();
          if (wasRepaired) {
            // Refresh token in auth context
            refreshToken();
          }
        }
        
        return response;
      } catch (error) {
        // Process through error handler
        ErrorHandler.handleError(error, {
          metadata: { fetchArgs: args[0] }
        });
        throw error;
      }
    };
    
    // Global error handler for uncaught exceptions
    const originalOnError = window.onerror;
    window.onerror = (message, source, lineno, colno, error) => {
      // Process through error handler
      ErrorHandler.handleError(error || new Error(message), {
        metadata: { source, lineno, colno }
      });
      
      // Call original handler if it exists
      if (typeof originalOnError === 'function') {
        return originalOnError(message, source, lineno, colno, error);
      }
      
      // Prevent default browser error handling
      return true;
    };
    
    // Global promise rejection handler
    const originalOnUnhandledRejection = window.onunhandledrejection;
    window.onunhandledrejection = (event) => {
      // Process through error handler
      ErrorHandler.handleError(event.reason, {
        userMessage: 'An unexpected error occurred. Please try again.'
      });
      
      // Call original handler if it exists
      if (typeof originalOnUnhandledRejection === 'function') {
        return originalOnUnhandledRejection(event);
      }
    };
    
    // Cleanup
    return () => {
      window.fetch = originalFetch;
      window.onerror = originalOnError;
      window.onunhandledrejection = originalOnUnhandledRejection;
    };
  }, [refreshToken]);
  
  return (
    <>
      <Navbar />
      <main>
        <ErrorBoundary 
          refreshToken={refreshToken} 
          loginWithDemoCredentials={loginWithDemoCredentials}
          isTopLevel={true}
          fallback={(error, resetError) => (
            <ErrorFallback error={error} resetError={resetError} />
          )}
        >
          <Suspense fallback={<LoadingFallback />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/demo" element={<DemoPage />} />
              <Route path="/table" element={<TablePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/program" element={
                <ProtectedRoute>
                  <Program />
                </ProtectedRoute>
              } />
              <Route path="/user/home" element={
                <ProtectedRoute>
                  <UserHomePage />
                </ProtectedRoute>
              } />
              <Route path="/user/claims" element={
                <ProtectedRoute>
                  <AllClaimsPage />
                </ProtectedRoute>
              } />
              <Route path="/user/activity" element={
                <ProtectedRoute>
                  <ActivityPage />
                </ProtectedRoute>
              } />
              {/* Redirect to 404 for dashboard routes */}
              <Route path="/dashboard/*" element={<NotFound />} />
              <Route path="/user/*" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </main>
      <Footer />
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <ToastProvider>
        <ProjectProvider>
          <Router>
            <AppContent />
          </Router>
        </ProjectProvider>
      </ToastProvider>
    </AuthProvider>
  );
}

export default App;