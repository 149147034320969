// API base URL - centralized config
const isLocalhost = window.location.hostname === 'localhost' || 
                    window.location.hostname === '127.0.0.1' ||
                    window.location.hostname.includes('192.168.');

// Set development API options with priority
const DEV_API_URL = isLocalhost ? 'http://localhost:8000/api' : null;
const LOCAL_FILE_API_URL = isLocalhost ? 'http://localhost/api' : null; // Fallback for local file server

// Check if an environment variable override exists
const ENV_API_URL = process.env.REACT_APP_API_URL;

// Use the priority: ENV > DEV > LOCAL_FILE > PRODUCTION
export const API_BASE_URL = ENV_API_URL || 
                           DEV_API_URL || 
                           LOCAL_FILE_API_URL ||
                           'https://contents.team/api';

// Define API endpoints - Make sure we're pointing to the correct directory
export const API_URL = `${API_BASE_URL}/projects`;
export const AUTH_URL = `${API_BASE_URL}/auth`;

// Log the chosen API URL for debugging
console.log(`API configured to use: ${API_BASE_URL}`);
console.log(`Projects API URL: ${API_URL}`);
console.log(`Auth API URL: ${AUTH_URL}`);

// Other configuration constants can be added here
export const APP_VERSION = '1.0.0';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

// Feature flags
export const FEATURES = {
  // Set to true to bypass API health checks (for development)
  SKIP_API_HEALTH_CHECK: isLocalhost,
  // Set to true to use mock data instead of real API calls (for development)
  USE_MOCK_DATA: false,
  // Force use of secure connection (HTTPS)
  REQUIRE_HTTPS: !isLocalhost,
  // Set to true to enable development features
  DEVELOPMENT_MODE: isLocalhost,
  // Allow CORS for development
  ALLOW_CORS: true,
  // Enable detailed API logging
  DEBUG_API_CALLS: isLocalhost
};

// Debug settings
export const DEBUG = {
  LOG_API_CALLS: true, // Always log API calls for debugging
  SHOW_DEV_TOOLS: isLocalhost
};
