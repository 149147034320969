// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { AUTH_URL } from '../config';
import { apiClient } from '../utils/lodashApiUtils';
import { debugToken, attemptTokenRepair } from '../utils/authDebug';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [authInitialized, setAuthInitialized] = useState(false);

  // Validate and format token
  const validateToken = useCallback((token) => {
    if (!token) return null;
    
    // Check if token is in the expected format
    if (typeof token !== 'string') return null;
    
    // Add Bearer prefix if missing
    return token.startsWith('Bearer ') ? token : `Bearer ${token}`;
  }, []);
  
  // Store token with proper formatting
  const storeToken = useCallback((token) => {
    const formattedToken = validateToken(token);
    if (formattedToken) {
      localStorage.setItem('token', formattedToken);
      console.log('Token stored successfully');
      return true;
    }
    return false;
  }, [validateToken]);

  // Force token refresh - for troubleshooting
  const refreshToken = useCallback(async () => {
    try {
      console.log('Refreshing authentication token...');
      // First, debug the current token
      const { hasToken } = debugToken();
      
      // No token - can't do much
      if (!hasToken) {
        console.warn('No token to refresh');
        return false;
      }
      
      // Attempt to repair token format if needed
      const repaired = attemptTokenRepair();
      if (repaired) {
        console.log('Token format repaired during refresh');
      }
      
      // Check for user data - if we have token but no user, try to get user data
      const storedUser = localStorage.getItem('user');
      if (!storedUser && hasToken) {
        try {
          // Call an API endpoint that returns user data if token is valid
          // This would be your "verify token" or "me" endpoint
          const response = await apiClient.get(`${AUTH_URL}/verify-token.php`);
          
          if (response && response.user) {
            // We got user data, store it
            localStorage.setItem('user', JSON.stringify(response.user));
            setUser(response.user);
            console.log('User data retrieved during token refresh');
            return true;
          }
        } catch (error) {
          console.error('Token appears invalid:', error);
          return false;
        }
      } else if (storedUser && !user) {
        // We have stored user data but not in state - set it
        try {
          setUser(JSON.parse(storedUser));
          console.log('User state updated from localStorage during refresh');
          return true;
        } catch (e) {
          console.error('Failed to parse stored user data:', e);
          return false;
        }
      }
      
      return !!user;
    } catch (e) {
      console.error('Error refreshing token:', e);
      return false;
    }
  }, [user]);

  // Initialize authentication state
  useEffect(() => {
    if (authInitialized) return;
    
    // Check for stored auth data on component mount
    const token = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    
    console.log('Auth initialization - token exists:', !!token);
    console.log('Auth initialization - user data exists:', !!storedUser);
    
    if (token && storedUser) {
      try {
        // Ensure the token is properly formatted for API requests
        const formattedToken = validateToken(token);
        if (formattedToken !== token) {
          // Update the stored token if format changed
          localStorage.setItem('token', formattedToken);
          console.log('Token format corrected on initialization');
        }
        
        // Parse user data
        const userData = JSON.parse(storedUser);
        setUser(userData);
        console.log('User authenticated from localStorage:', userData.name || userData.email || 'Unknown user');
        
        // Optional: set a flag that the user is returning
        localStorage.setItem('returning', 'true');
      } catch (e) {
        // Invalid user data in localStorage
        console.error('Error parsing stored user data:', e);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
    } else if (token && !storedUser) {
      // We have a token but no user data - try to get user data from the server
      console.log('Token exists but no user data - attempting to fetch user data');
      refreshToken();
    }
    
    setLoading(false);
    setAuthInitialized(true);
  }, [validateToken, refreshToken, authInitialized]);

  // Automatic login with demo credentials
  const loginWithDemoCredentials = async () => {
    try {
      setLoading(true);
      setError(null);
      
      console.log('Attempting login with demo credentials'); 

      // Use the demo credentials 
      const response = await apiClient.post(`${AUTH_URL}/login.php`, {
        email: 'demo@example.com', 
        password: 'demo123'
      }, {}, false); // No auth token needed for login

      if (!response || !response.token || !response.user) {
        throw new Error('Invalid response from server');
      }
      
      // Store token with Bearer prefix for API calls
      const success = storeToken(response.token);
      if (!success) {
        throw new Error('Failed to store authentication token');
      }
      
      localStorage.setItem('user', JSON.stringify(response.user));
      
      console.log('Login successful with demo account');
      setUser(response.user);
      return true;
    } catch (err) {
      console.error('Login error:', err.message);
      setError(err.message || 'Login failed. Please try again.');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const login = async (email, password) => {
    try {
      setLoading(true);
      setError(null);
      
      console.log('Attempting login...', { email }); // Debug log

      // Use the apiClient instead of ApiUtils
      const response = await apiClient.post(`${AUTH_URL}/login.php`, {
        email, 
        password
      }, {}, false); // No auth token needed for login

      if (!response || !response.token || !response.user) {
        throw new Error('Invalid response from server');
      }
      
      // Store token with Bearer prefix for API calls
      const success = storeToken(response.token);
      if (!success) {
        throw new Error('Failed to store authentication token');
      }
      
      // Double-check token format and fix if needed (important for production)
      const storedToken = localStorage.getItem('token');
      if (storedToken && !storedToken.startsWith('Bearer ')) {
        const fixedToken = `Bearer ${storedToken}`;
        localStorage.setItem('token', fixedToken);
        console.log('Fixed token format after login');
      }
      
      localStorage.setItem('user', JSON.stringify(response.user));
      
      console.log('Login successful, token stored securely');
      setUser(response.user);
      return true;
    } catch (err) {
      console.error('Login error:', err.message); // Debug log
      setError(err.message || 'Login failed. Please try again.');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const register = async (name, email, password) => {
    try {
      setLoading(true);
      setError(null);
      
      console.log('Attempting registration...', { name, email }); // Debug log

      // Use the apiClient instead of ApiUtils
      const response = await apiClient.post(`${AUTH_URL}/register.php`, {
        name, 
        email, 
        password
      }, {}, false); // No auth token needed for registration

      if (!response || !response.token || !response.user) {
        throw new Error('Invalid response from server');
      }

      // Store token with Bearer prefix for API calls
      const success = storeToken(response.token);
      if (!success) {
        throw new Error('Failed to store authentication token');
      }
      
      localStorage.setItem('user', JSON.stringify(response.user));
      
      console.log('Registration successful');
      setUser(response.user);
      return true;
    } catch (err) {
      console.error('Registration error:', err.message);
      setError(err.message || 'Registration failed. Please try again.');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    console.log('Logging out user');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
    // Reset rate limiting on logout
    apiClient.resetRateLimiting();
  };

  return (
    <AuthContext.Provider 
      value={{
        user,
        loading,
        error,
        login,
        register,
        logout,
        refreshToken,
        loginWithDemoCredentials,
        setError
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};