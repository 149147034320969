import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { ProjectService } from '../services/ProjectService';
import { useToast } from './ToastContext';
import _ from 'lodash';

// Create context for projects
const ProjectContext = createContext();

// Custom hook to use the project context
export const useProjects = () => useContext(ProjectContext);

// Provider component for project context
export const ProjectProvider = ({ children }) => {
  const { user } = useAuth();
  const { showError, showSuccess } = useToast();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);

  // Create a debounced version of fetchProjects that won't execute too frequently
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchProjects = useCallback(
    _.debounce(async (forceRefresh = false) => {
      if (!user) return;
      
      try {
        setLoading(true);
        setError(null);
        
        const data = await ProjectService.getUserProjects(forceRefresh);
        if (Array.isArray(data)) {
          setProjects(data);
        } else {
          console.warn('Unexpected data format from getUserProjects', data);
          setProjects([]);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
        setError(error.message || 'Failed to load projects');
        // Don't show too many toast errors - only for significant issues
        if (!error.message.includes('wait a moment')) {
          showError(error.message || 'Failed to load projects');
        }
      } finally {
        setLoading(false);
      }
    }, 1000, { leading: true, trailing: true, maxWait: 5000 }),
    [user, showError]
  );

  // Fetch user projects when the user changes
  useEffect(() => {
    if (!user) {
      setProjects([]);
      setCurrentProject(null);
      return;
    }
    
    fetchProjects();
    
    // Set up periodic refresh every 2 minutes
    if (window.projectRefreshInterval) {
      clearInterval(window.projectRefreshInterval);
    }
    
    window.projectRefreshInterval = setInterval(() => {
      fetchProjects(true);
    }, 120000);
    
    return () => {
      if (window.projectRefreshInterval) {
        clearInterval(window.projectRefreshInterval);
        window.projectRefreshInterval = null;
      }
    };
  }, [user, fetchProjects]);
  
  // Function to save a project
  const saveProject = async (name, projectData) => {
    try {
      setLoading(true);
      console.log(`ProjectContext: Saving new project with name "${name}"`);
      
      // Call the service with retry logic in case of failure
      let result;
      let retries = 3;
      let success = false;
      
      while (retries > 0 && !success) {
        try {
          result = await ProjectService.saveProject(name, projectData);
          success = true;
        } catch (serviceError) {
          console.warn(`Save attempt failed (${retries} retries left):`, serviceError.message);
          retries--;
          if (retries <= 0) throw serviceError;
          // Wait before retrying
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
      
      // If we got here, the save was successful
      console.log('Project save successful:', result);
      
      // Add the new project to the projects list
      const newProject = {
        id: result.projectId,
        name: name,
        date: new Date().toISOString(),
        lastUpdated: new Date().toISOString(),
        items: projectData.analysisResults?.items?.length || 0,
        value: projectData.analysisResults?.totalValue || 0,
        status: 'Saved'
      };
      
      setProjects(prev => [newProject, ...prev]);
      setCurrentProject(newProject);
      showSuccess('Project saved successfully');
      
      return result;
    } catch (error) {
      console.error('Error saving project:', error);
      showError(`Failed to save project: ${error.message}`);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  
  // Create a throttled version of updateProject to prevent too many update calls
  const updateProject = async (projectId, name, projectData) => {
    try {
      setLoading(true);
      console.log(`ProjectContext: Updating project ${projectId} with name "${name}"`);
      
      // Call the service with retry logic in case of failure
      let result;
      let retries = 3;
      let success = false;
      
      while (retries > 0 && !success) {
        try {
          result = await ProjectService.updateProject(projectId, name, projectData);
          success = true;
        } catch (serviceError) {
          console.warn(`Update attempt failed (${retries} retries left):`, serviceError.message);
          retries--;
          if (retries <= 0) throw serviceError;
          // Wait before retrying
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
      
      // If we got here, the update was successful
      console.log('Project update successful:', result);
      
      // Update the project in the projects list
      setProjects(prev => prev.map(project => 
        project.id === projectId
          ? {
              ...project,
              name: name,
              lastUpdated: new Date().toISOString(),
              items: projectData.analysisResults?.items?.length || 0,
              value: projectData.analysisResults?.totalValue || 0
            }
          : project
      ));
      
      // Update current project if needed
      if (currentProject?.id === projectId) {
        setCurrentProject(prev => ({
          ...prev,
          name: name,
          lastUpdated: new Date().toISOString(),
          items: projectData.analysisResults?.items?.length || 0,
          value: projectData.analysisResults?.totalValue || 0
        }));
      }
      
      showSuccess('Project updated successfully');
      return result;
    } catch (error) {
      console.error('Error updating project:', error);
      // Only show error messages that aren't rate-limiting related
      if (!error.message.includes('wait a moment')) {
        showError(`Failed to update project: ${error.message}`);
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };
  
  // Function to delete a project
  const deleteProject = async (projectId) => {
    try {
      setLoading(true);
      console.log(`ProjectContext: Deleting project ${projectId}`);
      
      // Call the service with retry logic in case of failure
      let result;
      let retries = 3;
      let success = false;
      
      while (retries > 0 && !success) {
        try {
          result = await ProjectService.deleteProject(projectId);
          success = true;
        } catch (serviceError) {
          console.warn(`Delete attempt failed (${retries} retries left):`, serviceError.message);
          retries--;
          if (retries <= 0) throw serviceError;
          // Wait before retrying
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
      
      // If we got here, the delete was successful
      console.log('Project delete successful:', result);
      
      // Remove the project from the projects list
      setProjects(prev => prev.filter(project => project.id !== projectId));
      
      // Clear current project if it was deleted
      if (currentProject?.id === projectId) {
        setCurrentProject(null);
      }
      
      showSuccess('Project deleted successfully');
      return { success: true };
    } catch (error) {
      console.error('Error deleting project:', error);
      showError(`Failed to delete project: ${error.message}`);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  
  // Create a memoized version of loadProject that won't repeat identical calls
  const loadProject = useCallback(async (projectId) => {
    try {
      setLoading(true);
      setError(null);
      const data = await ProjectService.getProject(projectId);
      
      if (data.status === 'success') {
        setCurrentProject(data.project);
        return data.project;
      } else {
        throw new Error(data.message || 'Failed to load project');
      }
    } catch (error) {
      console.error('Error loading project:', error);
      setError(error.message);
      
      // Only show errors that aren't related to rate limiting
      if (!error.message.includes('wait a moment')) {
        showError(error.message || 'Failed to load project');
      }
      
      throw error;
    } finally {
      setLoading(false);
    }
  }, [showError]);
  
  // Create a debounced version of the refresh function
  const refreshProjects = useCallback(
    _.debounce((forceRefresh = true) => fetchProjects(forceRefresh), 
    1000, 
    { leading: true, trailing: false }),
    [fetchProjects]
  );

  return (
    <ProjectContext.Provider
      value={{
        projects,
        loading,
        error,
        currentProject,
        saveProject,
        updateProject,
        deleteProject,
        loadProject,
        refreshProjects
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};