import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Menu, X, ChevronDown, User, LogOut, Settings, Home, Image, Table, FileText } from 'lucide-react';

const Navbar = () => {
  const { user, logout } = useAuth();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  // Check if a path is active
  const isActive = (path) => {
    if (path === '/' && location.pathname === '/') return true;
    if (path !== '/' && location.pathname.startsWith(path)) return true;
    return false;
  };

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuOpen && !event.target.closest('.user-menu-container')) {
        setUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [userMenuOpen]);

  // Handle logout
  const handleLogout = () => {
    setUserMenuOpen(false);
    logout();
  };

  return (
    <nav className={`sticky top-0 z-50 bg-white border-b border-gray-100 transition-shadow duration-300 ${scrolled ? 'shadow-sm' : ''}`}>
      <div className="container mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2">
              <span className="text-xl font-bold text-gray-700">Make My List</span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:items-center md:space-x-6">
            <div className="flex space-x-4">
              <Link
                to="/"
                className={`flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                  isActive('/') 
                    ? 'text-gray-800 bg-gray-50' 
                    : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                }`}
              >
                <Home size={18} className="mr-1.5" />
                <span>Home</span>
              </Link>
              <Link
                to="/demo"
                className={`flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                  isActive('/demo') 
                    ? 'text-gray-800 bg-gray-50' 
                    : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                }`}
              >
                <Image size={18} className="mr-1.5" />
                <span>Demo</span>
              </Link>
              <Link
                to="/table"
                className={`flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                  isActive('/table') 
                    ? 'text-gray-800 bg-gray-50' 
                    : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                }`}
              >
                <Table size={18} className="mr-1.5" />
                <span>Table</span>
              </Link>
              {user && (
                <>
                  <Link
                    to="/program"
                    className={`flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                      isActive('/program') 
                        ? 'text-gray-800 bg-gray-50' 
                        : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <FileText size={18} className="mr-1.5" />
                    <span>Program</span>
                  </Link>
                  <Link
                    to="/user/home"
                    className={`flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                      isActive('/user/home') 
                        ? 'text-gray-800 bg-gray-50' 
                        : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <User size={18} className="mr-1.5" />
                    <span>Dashboard</span>
                  </Link>
                </>
              )}
            </div>

            {/* Authentication Section */}
            {user ? (
              <div className="relative user-menu-container">
                <button
                  onClick={() => setUserMenuOpen(!userMenuOpen)}
                  className="flex items-center space-x-2 text-sm font-medium text-gray-600 hover:text-gray-800 focus:outline-none transition-colors px-3 py-2 rounded-full hover:bg-gray-50"
                >
                  <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center text-gray-700 font-medium uppercase">
                    {user.name ? user.name.charAt(0) : 'U'}
                  </div>
                  <span className="hidden lg:inline-block">{user.name || 'User'}</span>
                  <ChevronDown size={16} className={`transition-transform duration-200 ${userMenuOpen ? 'transform rotate-180' : ''}`} />
                </button>

                {/* User Dropdown Menu */}
                {userMenuOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-sm py-1 z-10 border border-gray-100">
                    <div className="px-4 py-2 border-b border-gray-50">
                      <p className="text-sm font-medium text-gray-800">{user.name}</p>
                      <p className="text-xs text-gray-500 truncate">{user.email}</p>
                    </div>
                    <Link
                      to="/user/profile"
                      className="flex items-center px-4 py-2 text-sm text-gray-600 hover:bg-gray-50"
                      onClick={() => setUserMenuOpen(false)}
                    >
                      <User size={16} className="mr-2" />
                      <span>Your Profile</span>
                    </Link>
                    <Link
                      to="/user/settings"
                      className="flex items-center px-4 py-2 text-sm text-gray-600 hover:bg-gray-50"
                      onClick={() => setUserMenuOpen(false)}
                    >
                      <Settings size={16} className="mr-2" />
                      <span>Settings</span>
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="flex w-full items-center px-4 py-2 text-sm text-gray-600 hover:bg-gray-50"
                    >
                      <LogOut size={16} className="mr-2" />
                      <span>Log Out</span>
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                <Link
                  to="/login"
                  className="px-4 py-2 border border-gray-200 rounded-md text-sm font-medium text-gray-600 hover:bg-gray-50 transition-colors"
                >
                  Log In
                </Link>
                <Link
                  to="/demo"
                  className="px-4 py-2 bg-gray-700 border border-transparent rounded-md text-sm font-medium text-white hover:bg-gray-800 transition-colors"
                >
                  Book a Demo
                </Link>
              </div>
            )}
          </div>

          {/* Mobile Menu Button */}
          <div className="flex items-center md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
              aria-expanded="false"
            >
              <span className="sr-only">{isOpen ? 'Close main menu' : 'Open main menu'}</span>
              {isOpen ? (
                <X className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="pt-2 pb-3 space-y-1 border-t border-gray-100">
            <Link
              to="/"
              className={`block pl-3 pr-4 py-2 text-base font-medium ${
                isActive('/') 
                  ? 'text-gray-800 bg-gray-50 border-l-4 border-gray-300' 
                  : 'text-gray-500 hover:bg-gray-50 hover:text-gray-700 border-l-4 border-transparent'
              }`}
              onClick={() => setIsOpen(false)}
            >
              Home
            </Link>
            <Link
              to="/demo"
              className={`block pl-3 pr-4 py-2 text-base font-medium ${
                isActive('/demo') 
                  ? 'text-gray-800 bg-gray-50 border-l-4 border-gray-300' 
                  : 'text-gray-500 hover:bg-gray-50 hover:text-gray-700 border-l-4 border-transparent'
              }`}
              onClick={() => setIsOpen(false)}
            >
              Demo
            </Link>
            <Link
              to="/table"
              className={`block pl-3 pr-4 py-2 text-base font-medium ${
                isActive('/table') 
                  ? 'text-gray-800 bg-gray-50 border-l-4 border-gray-300' 
                  : 'text-gray-500 hover:bg-gray-50 hover:text-gray-700 border-l-4 border-transparent'
              }`}
              onClick={() => setIsOpen(false)}
            >
              Table
            </Link>
            {user && (
              <>
                <Link
                  to="/program"
                  className={`block pl-3 pr-4 py-2 text-base font-medium ${
                    isActive('/program') 
                      ? 'text-gray-800 bg-gray-50 border-l-4 border-gray-300' 
                      : 'text-gray-500 hover:bg-gray-50 hover:text-gray-700 border-l-4 border-transparent'
                  }`}
                  onClick={() => setIsOpen(false)}
                >
                  Program
                </Link>
                <Link
                  to="/user/home"
                  className={`block pl-3 pr-4 py-2 text-base font-medium ${
                    isActive('/user/home') 
                      ? 'text-gray-800 bg-gray-50 border-l-4 border-gray-300' 
                      : 'text-gray-500 hover:bg-gray-50 hover:text-gray-700 border-l-4 border-transparent'
                  }`}
                  onClick={() => setIsOpen(false)}
                >
                  Dashboard
                </Link>
              </>
            )}
          </div>

          {/* Mobile Auth Section */}
          <div className="pt-4 pb-3 border-t border-gray-100">
            {user ? (
              <>
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <div className="h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center text-gray-700 font-bold">
                      {user.name ? user.name.charAt(0) : 'U'}
                    </div>
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">{user.name || 'User'}</div>
                    <div className="text-sm font-medium text-gray-500">{user.email}</div>
                  </div>
                </div>
                <div className="mt-3 space-y-1">
                  <Link
                    to="/user/profile"
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-700 hover:bg-gray-50"
                    onClick={() => setIsOpen(false)}
                  >
                    Your Profile
                  </Link>
                  <Link
                    to="/user/settings"
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-700 hover:bg-gray-50"
                    onClick={() => setIsOpen(false)}
                  >
                    Settings
                  </Link>
                  <button
                    onClick={() => {
                      setIsOpen(false);
                      logout();
                    }}
                    className="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-700 hover:bg-gray-50"
                  >
                    Log Out
                  </button>
                </div>
              </>
            ) : (
              <div className="mt-3 space-y-1 px-4">
                <Link
                  to="/login"
                  className="block w-full py-2 text-center px-4 border border-gray-200 rounded-md text-base font-medium text-gray-600 hover:bg-gray-50"
                  onClick={() => setIsOpen(false)}
                >
                  Log In
                </Link>
                <Link
                  to="/demo"
                  className="block w-full mt-2 py-2 text-center px-4 border border-transparent rounded-md text-base font-medium text-white bg-gray-700 hover:bg-gray-800"
                  onClick={() => setIsOpen(false)}
                >
                  Book a Demo
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar; 