/**
 * Utility class for caching data
 */
export class CacheManager {
  constructor() {
    this.cache = new Map();
  }
  
  /**
   * Get item from cache if it exists and is not expired
   * @param {string} key - Cache key
   * @param {number} maxAge - Maximum age in milliseconds
   * @returns {any} Cached value or null if expired/missing
   */
  get(key, maxAge = 300000) {
    const item = this.cache.get(key);
    
    // If item doesn't exist, return null
    if (!item) {
      return null;
    }
    
    // If maxAge is Infinity, always return the cached value regardless of age
    if (maxAge === Infinity) {
      return item.value;
    }
    
    // Check if expired
    const now = Date.now();
    if (now - item.timestamp > maxAge) {
      // Don't delete, it might be useful as a stale value
      return null;
    }
    
    return item.value;
  }
  
  /**
   * Set item in cache
   * @param {string} key - Cache key
   * @param {any} value - Value to cache
   * @returns {void}
   */
  set(key, value) {
    this.cache.set(key, {
      value,
      timestamp: Date.now()
    });
  }
  
  /**
   * Check if cache has non-expired value
   * @param {string} key - Cache key
   * @param {number} maxAge - Maximum age in milliseconds
   * @returns {boolean} True if valid cache entry exists
   */
  has(key, maxAge = 300000) {
    return this.get(key, maxAge) !== null;
  }
  
  /**
   * Remove item from cache
   * @param {string} key - Cache key
   * @returns {void}
   */
  delete(key) {
    this.cache.delete(key);
  }
  
  /**
   * Remove item from cache (alias for delete)
   * @param {string} key - Cache key
   * @returns {void}
   */
  remove(key) {
    return this.delete(key);
  }
  
  /**
   * Clear entire cache
   * @returns {void}
   */
  clear() {
    this.cache.clear();
  }
}

// Create a singleton instance
export const globalCache = new CacheManager(); 