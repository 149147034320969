import { API_URL } from '../config';
// Replace ApiUtils with our enhanced lodash-based API client
import { apiClient } from '../utils/lodashApiUtils';
import { globalCache } from '../utils/cacheUtils';
import { retryWithBackoff } from '../utils/retryUtils';

/**
 * Service for managing projects - handles all project-related API operations
 */
export class ProjectService {
  // Cache for projects data
  static projectsCache = null;
  static lastFetchTime = 0;
  static CACHE_TTL = 60000; // Increase cache lifetime to 60 seconds (from 30)
  static MIN_REQUEST_INTERVAL = 5000; // Increase minimum time between identical requests to 5 seconds (from 2)
  static lastRequestTimestamps = new Map(); // Track timestamps of requests by endpoint
  static blockedEndpoints = new Set(); // Track endpoints that are currently rate-limited
  static RATE_LIMIT_DURATION = 10000; // Duration to block an endpoint after rate limiting (10 seconds)
  
  /**
   * Helper method to get the base API URL based on environment
   * @returns {string} The base API URL
   */
  static getBaseApiUrl() {
    return window.location.hostname === 'localhost' 
      ? 'http://localhost:8000' 
      : 'https://contents.team';
  }
  
  /**
   * Helper method to construct a full API URL
   * @param {string} endpoint - The API endpoint path
   * @returns {string} The full API URL
   */
  static getFullApiUrl(endpoint) {
    // Ensure endpoint starts with a slash if not already
    const formattedEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`;
    return `${this.getBaseApiUrl()}${formattedEndpoint}`;
  }
  
  /**
   * Safe wrapper for globalCache operations that prevents errors
   * when methods don't exist or objects are undefined
   */
  static _safeCache = {
    get: (key, ttl = 300000) => {
      try {
        if (globalCache && typeof globalCache.get === 'function') {
          console.log(`_safeCache: Getting key ${key} from globalCache`);
          return globalCache.get(key, ttl);
        }
      } catch (e) {
        console.warn(`_safeCache: Error getting ${key} from cache:`, e.message);
      }
      console.log(`_safeCache: Cache miss or unavailable for key ${key}`);
      return null;
    },
    
    set: (key, value) => {
      try {
        if (globalCache && typeof globalCache.set === 'function') {
          console.log(`_safeCache: Setting key ${key} in globalCache`);
          globalCache.set(key, value);
          return true;
        }
      } catch (e) {
        console.warn(`_safeCache: Error setting ${key} in cache:`, e.message);
      }
      console.log(`_safeCache: Unable to set key ${key} - cache unavailable`);
      return false;
    },
    
    remove: (key) => {
      try {
        if (globalCache) {
          // Try multiple methods that might exist
          if (typeof globalCache.remove === 'function') {
            console.log(`_safeCache: Removing key ${key} with remove() method`);
            globalCache.remove(key);
            return true;
          } else if (typeof globalCache.delete === 'function') {
            console.log(`_safeCache: Removing key ${key} with delete() method`);
            globalCache.delete(key);
            return true;
          } else if (typeof globalCache.clear === 'function') {
            // If we can't remove specific keys, clear the whole cache as fallback
            console.log(`_safeCache: No specific key deletion available, clearing entire cache`);
            globalCache.clear();
            return true;
          } else {
            // Last resort: try to set the key to null
            console.log(`_safeCache: No deletion methods available, setting ${key} to null`);
            if (typeof globalCache.set === 'function') {
              globalCache.set(key, null);
              return true;
            }
          }
        }
      } catch (e) {
        console.warn(`_safeCache: Error removing ${key} from cache:`, e.message);
      }
      console.log(`_safeCache: Unable to remove key ${key} - all methods failed`);
      return false;
    }
  };
  
  /**
   * Checks if a valid authentication token exists
   * @returns {boolean} True if a token exists
   */
  static hasValidToken() {
    const token = localStorage.getItem('token');
    return !!token;
  }
  
  /**
   * Validates authentication before making API calls
   * @throws {Error} If token is missing
   */
  static validateAuth() {
    try {
      if (!this.hasValidToken()) {
        console.error('Authentication validation failed in ProjectService');
        
        // Gracefully handle token issues
        const token = localStorage.getItem('token');
        
        // Log details for debugging
        console.debug('Token exists:', !!token);
        
        if (token) {
          console.debug('Token starts with Bearer:', token.startsWith('Bearer '));
          
          // If token exists but doesn't have Bearer prefix, fix it
          if (!token.startsWith('Bearer ')) {
            const fixedToken = `Bearer ${token}`;
            localStorage.setItem('token', fixedToken);
            console.log('Token format fixed - added Bearer prefix');
            
            // Retry validation after fixing token
            return this.hasValidToken();
          }
        } else {
          // Check if user data exists without token - could be a sync issue
          const userData = localStorage.getItem('user');
          if (userData) {
            console.warn('User data exists but token is missing - possible sync issue');
            // Clear user data to force re-login
            localStorage.removeItem('user');
          }
        }
        
        // If no token or repair didn't help, throw
        throw new Error('Authentication token required or invalid');
      }
      
      return true;
    } catch (error) {
      console.error('Auth validation error:', error);
      throw error;
    }
  }
  
  /**
   * Determines if a request should be rate limited based on recent activity
   * @param {string} operation - Unique identifier for the operation
   * @returns {boolean} True if the request should be rate limited
   */
  static shouldRateLimit(operation) {
    const now = Date.now();
    const lastRequestTime = this.lastRequestTimestamps.get(operation) || 0;
    const timeSinceLastRequest = now - lastRequestTime;
    
    // Check if the endpoint is currently blocked due to rate limiting
    if (this.blockedEndpoints.has(operation)) {
      const blockExpiry = this.lastRequestTimestamps.get(`${operation}-block-until`) || 0;
      if (now < blockExpiry) {
        console.log(`Operation ${operation} is blocked due to rate limiting until ${new Date(blockExpiry).toISOString()}`);
        return true;
      } else {
        // Unblock the endpoint if the block duration has passed
        this.blockedEndpoints.delete(operation);
        console.log(`Unblocking operation ${operation} as rate limit duration has passed`);
      }
    }
    
    // If we've made a request too recently, rate limit
    if (timeSinceLastRequest < this.MIN_REQUEST_INTERVAL) {
      console.log(`Rate limiting ${operation}: ${timeSinceLastRequest}ms since last request (min: ${this.MIN_REQUEST_INTERVAL}ms)`);
      
      // Block this endpoint for the rate limit duration
      this.blockedEndpoints.add(operation);
      this.lastRequestTimestamps.set(`${operation}-block-until`, now + this.RATE_LIMIT_DURATION);
      
      return true;
    }
    
    // Update the timestamp for this operation
    this.lastRequestTimestamps.set(operation, now);
    return false;
  }
  
  /**
   * Normalize API response to ensure consistent property names
   * @param {object} response - API response object
   * @returns {object} Normalized response object
   */
  static normalizeResponse(response) {
    if (!response) return response;
    
    // Create a copy to avoid modifying the original
    const normalized = { ...response };
    
    // Handle project ID format inconsistencies
    if (!normalized.projectId && normalized.project_id) {
      normalized.projectId = normalized.project_id;
    }
    
    return normalized;
  }
  
  /**
   * Save a new project
   * @param {string} name - Name of the project
   * @param {object} projectData - Project data to save
   * @returns {Promise<object>} Response with the new project ID
   */
  static async saveProject(name, projectData) {
    // Check authentication first
    this.validateAuth();
    
    try {
      console.log(`ProjectService: Saving new project with name "${name}"`);
      
      // Use the helper method for the save project URL
      const saveUrl = this.getFullApiUrl('/api/projects/save-project.php');
      
      // Extract items from project data for separate saving
      const items = projectData.analysisResults?.items || [];
      console.log(`Extracted ${items.length} items to save separately`);
      
      // Prepare the request data
      const requestData = {
        name: name,
        project_data: JSON.stringify(projectData)
      };
      
      // Use improved fetch logic with detailed error handling
      const response = await apiClient.post(
        saveUrl,
        requestData,
        {}, // Default options
        true // Use authentication
      );
      
      console.log('Save project response:', response);
      
      if (response && response.status === 'success') {
        // Ensure we have a valid project ID
        if (!response.projectId) {
          console.error('API returned success but no project ID');
          throw new Error('Project ID not found in API response');
        }
        
        const projectId = response.projectId;
        
        // If we have items, save them separately to the claim_items table
        if (items.length > 0) {
          try {
            console.log(`Saving ${items.length} items to claim_items table for project ${projectId}`);
            
            // Use the helper method for the save items URL
            const saveItemsUrl = this.getFullApiUrl('/api/projects/save-items.php');
            
            // Prepare the items data
            const itemsData = {
              project_id: projectId,
              items: items.map(item => ({
                name: item.name || '',
                model: item.model || '',
                source: item.source || '',
                quantity: item.quantity || 1,
                price: item.price !== undefined ? parseFloat(item.price) : 0,
                total_price: item.totalPrice !== undefined ? parseFloat(item.totalPrice) : 0,
                age: item.age || ''
              }))
            };
            
            // Save the items
            const itemsResponse = await apiClient.post(
              saveItemsUrl,
              itemsData,
              {}, // Default options
              true // Use authentication
            );
            
            console.log('Save items response:', itemsResponse);
            
            if (itemsResponse && itemsResponse.status === 'success') {
              console.log(`Successfully saved ${items.length} items to claim_items table`);
            } else {
              console.error('Failed to save items, API returned:', itemsResponse);
            }
          } catch (itemsError) {
            console.error('Error saving items:', itemsError);
            // Continue with the main project save even if items save fails
          }
        }
        
        // Invalidate cache for this project
        this.invalidateCache();
        
        return response;
      }
      
      // If we got a non-success response
      console.error('Failed to save project, API returned:', response);
      
      // Check for specific error types
      if (response && response.error === 'schema_validation') {
        console.warn('Schema validation error, attempting to fix...');
        
        try {
          // Try to fix the schema by ensuring project_data is a string
          if (typeof requestData.project_data !== 'string') {
            requestData.project_data = JSON.stringify(requestData.project_data);
          }
          
          // Retry the request with fixed data
          const retryResponse = await apiClient.post(
            saveUrl,
            requestData,
            {}, // Default options
            true // Use authentication
          );
          
          if (retryResponse && retryResponse.status === 'success') {
            console.log('Successfully saved project after fixing schema');
            return retryResponse;
          }
        } catch (retryError) {
          console.error('Error in retry after schema fix:', retryError);
        }
        
        // Rethrow the original error if it wasn't a schema issue or the fix didn't work
        throw new Error(response?.message || 'Failed to save project');
      }
      
      throw new Error(response?.message || 'Failed to save project');
    } catch (error) {
      console.error('Error in saveProject:', error);
      throw error;
    }
  }
  
  /**
   * Update an existing project
   * @param {string|number} projectId - ID of the project to update
   * @param {string} name - New name for the project
   * @param {object} projectData - Updated project data
   * @returns {Promise<object>} Response with updated project data
   */
  static async updateProject(projectId, name, projectData) {
    // Check authentication first
    this.validateAuth();
    
    try {
      // Use the helper method for the update project URL
      const updateUrl = this.getFullApiUrl('/api/projects/update-project.php');
      
      // Extract items from project data for separate saving
      const items = projectData.analysisResults?.items || [];
      console.log(`Extracted ${items.length} items to update separately`);
      
      // Prepare the request data
      const requestData = {
        id: projectId,
        name: name,
        project_data: JSON.stringify(projectData)
      };
      
      // Use improved fetch logic with detailed error handling
      const response = await apiClient.post(
        updateUrl,
        requestData,
        {}, // Default options
        true // Use authentication
      );
      
      console.log('Update project response:', response);
      
      // If items exist, save them separately to the claim_items table
      if (items.length > 0) {
        try {
          console.log(`Saving ${items.length} items separately to claim_items table`);
          
          // Prepare the items data
          const itemsData = items.map(item => ({
            name: item.name || '',
            model: item.model || '',
            source: item.source || '',
            quantity: parseInt(item.quantity) || 1,
            price: item.price !== undefined ? parseFloat(item.price) : 0,
            total_price: item.totalPrice !== undefined ? parseFloat(item.totalPrice) : 0,
            age: item.age || ''
          }));
          
          // Make a separate API call to save the items
          const saveItemsUrl = this.getFullApiUrl('/api/projects/save-items.php');
          const itemsResponse = await apiClient.post(
            saveItemsUrl,
            {
              project_id: projectId,
              items: itemsData
            },
            {}, // Default options
            true // Use authentication
          );
          
          console.log('Save items response:', itemsResponse);
          
          if (itemsResponse && itemsResponse.status === 'success') {
            console.log(`Successfully saved ${itemsResponse.inserted_count || items.length} items`);
          } else {
            console.error('Error saving items:', itemsResponse);
          }
        } catch (itemsError) {
          console.error('Error saving items separately:', itemsError);
          // Continue with the main response even if items save fails
        }
      }
      
      // Invalidate the project cache to ensure we get the latest data next time
      this.invalidateCache(`project-${projectId}`);
      
      // Cache the update result
      const cacheKey = `updateProject-${projectId}`;
      this._safeCache.set(cacheKey, response);
      
      return response;
    } catch (error) {
      console.error('Error updating project:', error);
      throw error;
    }
  }
  
  /**
   * Get a specific project by ID
   * @param {string|number} projectId - ID of the project to retrieve
   * @returns {Promise<object>} Response with project data
   */
  static async getProject(projectId) {
    // Check if we're making too many requests for the same project
    const operationKey = `getProject-${projectId}`;
    if (this.shouldRateLimit(operationKey)) {
      // If rate limited, serve from stale cache without TTL check
      console.log(`Rate limited: ${operationKey}`);
      const cacheKey = `project-${projectId}`;
      const cachedData = this._safeCache.get(cacheKey, Infinity); // Ignore TTL
      
      if (cachedData) {
        console.log('Returning stale cache data due to rate limiting');
        return cachedData;
      }
      
      throw new Error('Please wait a moment before fetching again');
    }
    
    // Check authentication first
    this.validateAuth();
    
    try {
      console.log(`ProjectService: Getting project ${projectId}`);
      
      // Custom cache key for this specific project
      const cacheKey = `project-${projectId}`;
      
      // Check cache first
      const cachedProject = this._safeCache.get(cacheKey);
      if (cachedProject) {
        console.log(`Serving project ${projectId} from cache`);
        return cachedProject;
      }
      
      // Use the helper method for the get project URL
      const getProjectUrl = this.getFullApiUrl('/api/projects/get-project.php');
      
      // Make the request with the project ID as a query parameter
      const response = await apiClient.get(
        `${getProjectUrl}?id=${projectId}`,
        {}, // Default options
        true // Use authentication
      );
      
      console.log('Raw project response:', response);
      
      if (response && response.status === 'success' && response.project) {
        let project = response.project;
        
        // Try to fetch claim items separately
        try {
          console.log(`Fetching claim items separately for project ${projectId}`);
          const claimItemsUrl = this.getFullApiUrl('/api/projects/claim-items.php');
          const itemsResponse = await apiClient.get(
            `${claimItemsUrl}?project_id=${projectId}`,
            {}, // Default options
            true // Use authentication
          );
          
          if (itemsResponse && itemsResponse.status === 'success' && itemsResponse.items) {
            console.log(`Found ${itemsResponse.items.length} claim items for project ${projectId}`);
            project.claim_items = itemsResponse.items;
          }
        } catch (itemsError) {
          console.error('Error fetching claim items separately:', itemsError);
          
          // Try direct database query as fallback
          try {
            console.log(`Trying direct database query for claim items for project ${projectId}`);
            const directQueryUrl = this.getFullApiUrl('/api/projects/direct-query.php');
            const directQueryResponse = await apiClient.get(
              `${directQueryUrl}?project_id=${projectId}&table=claim_items`,
              {}, // Default options
              true // Use authentication
            );
            
            if (directQueryResponse && directQueryResponse.status === 'success' && directQueryResponse.data) {
              console.log(`Found ${directQueryResponse.data.length} claim items via direct query for project ${projectId}`);
              project.claim_items = directQueryResponse.data;
            }
          } catch (directQueryError) {
            console.error('Error with direct DB query for claim items:', directQueryError);
          }
        }
        
        // Check if we have claim_items in the response
        if (!project.claim_items || !Array.isArray(project.claim_items) || project.claim_items.length === 0) {
          console.log('No claim_items found in API response');
        }
        
        // Ensure project_data is properly parsed
        try {
          // If project_data is a string, parse it to an object
          if (project.project_data && typeof project.project_data === 'string') {
            try {
              console.log('Successfully parsed project_data from string to object');
              project.project_data = JSON.parse(project.project_data);
            } catch (parseError) {
              console.error('Error parsing project_data JSON:', parseError);
              // If parsing fails, initialize with empty structure
              project.project_data = {
                analysisResults: {
                  items: [],
                  totalValue: 0
                }
              };
            }
          }
          
          // Ensure we have the expected structure
          if (!project.project_data) {
            project.project_data = {};
          }
          
          if (!project.project_data.analysisResults) {
            project.project_data.analysisResults = {
              items: [],
              totalValue: 0
            };
          }
          
          // If we have claim_items, use them to populate analysisResults.items
          if (project.claim_items && Array.isArray(project.claim_items) && project.claim_items.length > 0) {
            console.log(`Using ${project.claim_items.length} claim_items to populate analysisResults.items`);
            
            // Map claim_items to the format expected by the frontend
            project.project_data.analysisResults.items = project.claim_items.map(item => ({
              id: item.id?.toString() || `item-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
              name: item.name || '',
              model: item.model || '',
              source: item.source || '',
              quantity: parseInt(item.quantity) || 1,
              price: item.price !== undefined ? parseFloat(item.price) : 0,
              totalPrice: item.total_price !== undefined ? parseFloat(item.total_price) : 0
            }));
            
            // Update totalValue
            project.project_data.analysisResults.totalValue = project.project_data.analysisResults.items.reduce(
              (sum, item) => sum + (item.totalPrice || 0), 
              0
            );
          }
        } catch (processingError) {
          console.error('Error processing project data:', processingError);
        }
        
        // Cache the processed project
        this._safeCache.set(cacheKey, project);
        
        return project;
      }
      
      throw new Error(response?.message || 'Failed to get project');
    } catch (error) {
      console.error('Error getting project', projectId, ':', error);
      throw error;
    }
  }
  
  /**
   * Ensure project_data is parsed from JSON string to object if needed
   * @param {object} project - Project object to process
   * @returns {object} Project with parsed project_data
   */
  static ensureProjectDataParsed(project) {
    if (!project) return project;
    
    try {
      // Make a copy to avoid modifying the original
      const processedProject = { ...project };
      
      // Check if project_data exists
      if (processedProject.project_data) {
        // If it's a string, try to parse it
        if (typeof processedProject.project_data === 'string') {
          try {
            console.log('Parsing project_data from string to object');
            processedProject.project_data = JSON.parse(processedProject.project_data);
            console.log('Successfully parsed project_data from string to object');
          } catch (parseError) {
            console.error('Error parsing project_data JSON:', parseError);
            // If parsing fails, initialize with empty structure
            processedProject.project_data = {
              analysisResults: {
                items: [],
                totalValue: 0
              }
            };
          }
        }
        
        // Ensure analysisResults exists
        if (!processedProject.project_data.analysisResults) {
          console.log('Project missing analysisResults, initializing empty structure');
          processedProject.project_data.analysisResults = {
            items: [],
            totalValue: 0
          };
        }
        
        // If we have claim_items, use them to populate analysisResults.items
        if (processedProject.claim_items && Array.isArray(processedProject.claim_items) && processedProject.claim_items.length > 0) {
          console.log(`Using ${processedProject.claim_items.length} claim_items to populate analysisResults.items`);
          
          // Map claim_items to the format expected by the frontend
          processedProject.project_data.analysisResults.items = processedProject.claim_items.map(item => ({
            id: item.id?.toString() || `item-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
            name: item.name || '',
            model: item.model || '',
            source: item.source || '',
            quantity: parseInt(item.quantity) || 1,
            price: item.price !== undefined ? parseFloat(item.price) : 0,
            totalPrice: item.total_price !== undefined ? parseFloat(item.total_price) : 0
          }));
          
          // Update totalValue
          processedProject.project_data.analysisResults.totalValue = processedProject.project_data.analysisResults.items.reduce(
            (sum, item) => sum + (parseFloat(item.totalPrice) || 0), 
            0
          );
          
          console.log(`Updated analysisResults with ${processedProject.project_data.analysisResults.items.length} items and total value ${processedProject.project_data.analysisResults.totalValue}`);
        }
      } else {
        // If project_data doesn't exist, initialize it
        console.log('Project missing project_data, initializing empty structure');
        processedProject.project_data = {
          analysisResults: {
            items: [],
            totalValue: 0
          }
        };
        
        // If we have claim_items but no project_data, create project_data from claim_items
        if (processedProject.claim_items && Array.isArray(processedProject.claim_items) && processedProject.claim_items.length > 0) {
          console.log(`Creating project_data from ${processedProject.claim_items.length} claim_items`);
          
          // Map claim_items to the format expected by the frontend
          processedProject.project_data.analysisResults.items = processedProject.claim_items.map(item => ({
            id: item.id?.toString() || `item-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
            name: item.name || '',
            model: item.model || '',
            source: item.source || '',
            quantity: parseInt(item.quantity) || 1,
            price: item.price !== undefined ? parseFloat(item.price) : 0,
            totalPrice: item.total_price !== undefined ? parseFloat(item.total_price) : 0
          }));
          
          // Update totalValue
          processedProject.project_data.analysisResults.totalValue = processedProject.project_data.analysisResults.items.reduce(
            (sum, item) => sum + (parseFloat(item.totalPrice) || 0), 
            0
          );
          
          console.log(`Created analysisResults with ${processedProject.project_data.analysisResults.items.length} items and total value ${processedProject.project_data.analysisResults.totalValue}`);
        }
      }
      
      return processedProject;
    } catch (error) {
      console.error('Error in ensureProjectDataParsed:', error);
      return project; // Return original if processing fails
    }
  }
  
  /**
   * Ensures a project has all required fields with proper structure
   * @param {object} project - The project object to normalize
   * @returns {object} Normalized project object
   */
  static normalizeProjectStructure(project) {
    if (!project) {
      console.error('Cannot normalize null project');
      return {
        id: null,
        name: 'Unknown Project',
        dataFormat: 'unknown',
        hasAnalysisResults: false,
        hasItems: false,
        itemCount: 0,
        project_data: {
          analysisResults: { items: [] },
          uploadedFiles: [],
          itemImageMap: {}
        }
      };
    }
    
    // Create base structure with defaults
    const normalized = {
      id: project.id || project.project_id || null,
      name: project.name || project.project_name || 'Unnamed Project',
      dataFormat: 'structured',
      created_at: project.created_at || new Date().toISOString(),
      hasItems: false,
      itemCount: 0,
      project_data: project.project_data || {
        analysisResults: { items: [] },
        uploadedFiles: [],
        itemImageMap: {}
      }
    };
    
    // Make sure project_data is an object
    if (typeof normalized.project_data === 'string') {
      try {
        normalized.project_data = JSON.parse(normalized.project_data);
      } catch (e) {
        console.error('Failed to parse project_data string:', e);
        normalized.project_data = {
          analysisResults: { items: [] },
          uploadedFiles: [],
          itemImageMap: {}
        };
      }
    }
    
    // Ensure we have analysisResults
    if (!normalized.project_data.analysisResults) {
      normalized.project_data.analysisResults = { items: [] };
    }
    
    // Ensure items is an array
    if (!Array.isArray(normalized.project_data.analysisResults.items)) {
      normalized.project_data.analysisResults.items = [];
    }
    
    // Calculate if we have items and how many
    normalized.itemCount = normalized.project_data.analysisResults.items.length;
    normalized.hasItems = normalized.itemCount > 0;
    normalized.hasAnalysisResults = normalized.hasItems;
    
    // Ensure we have uploadedFiles
    if (!Array.isArray(normalized.project_data.uploadedFiles)) {
      normalized.project_data.uploadedFiles = [];
    }
    
    // Ensure we have itemImageMap
    if (!normalized.project_data.itemImageMap || typeof normalized.project_data.itemImageMap !== 'object') {
      normalized.project_data.itemImageMap = {};
    }
    
    // Only convert claim_items if we don't already have items in analysisResults
    // This prevents overwriting items that were already processed in ensureProjectDataParsed
    if (project.claim_items && Array.isArray(project.claim_items) && project.claim_items.length > 0 && normalized.itemCount === 0) {
      console.log(`normalizeProjectStructure: Converting ${project.claim_items.length} claim items`);
      
      normalized.project_data.analysisResults.items = project.claim_items.map(item => ({
        id: item.id?.toString() || `item-${Math.random().toString(36).substring(2, 9)}`,
        name: item.name || item.item_name || 'Unknown Item',
        model: item.model || '',
        source: item.source || '',
        quantity: parseInt(item.quantity) || 1,
        estimatedValue: parseFloat(item.price || item.value || 0) || 0,
        totalValue: parseFloat(item.total_price || item.total_value || 0) || 0
      }));
      
      // Update counters
      normalized.itemCount = normalized.project_data.analysisResults.items.length;
      normalized.hasItems = normalized.itemCount > 0;
      normalized.hasAnalysisResults = normalized.hasItems;
      
      // Calculate total value
      normalized.project_data.analysisResults.totalValue = normalized.project_data.analysisResults.items.reduce(
        (sum, item) => sum + (item.totalValue || 0), 
        0
      );
      
      console.log(`normalizeProjectStructure: Updated project with ${normalized.itemCount} items and total value: ${normalized.project_data.analysisResults.totalValue}`);
    }
    
    return normalized;
  }
  
  /**
   * Get all projects for the current user
   * @param {boolean} forceRefresh - Force a fresh API call
   * @returns {Promise<Array>} Array of user projects
   */
  static async getUserProjects(forceRefresh = false) {
    // Check authentication first
    this.validateAuth();
    
    // Define a unique operation key for rate limiting
    const operationKey = 'getUserProjects';
    
    // Check if we should use cached data
    const now = Date.now();
    const timeSinceLastFetch = now - this.lastFetchTime;
    const shouldUseCache = !forceRefresh && 
                          this.projectsCache && 
                          timeSinceLastFetch < this.CACHE_TTL;
    
    // If we're not forcing a refresh and we have recent cache, use it
    if (shouldUseCache) {
      console.log('ProjectService: Serving user projects from in-memory cache');
      return this.projectsCache;
    }
    
    // Check if we're making too many requests
    if (this.shouldRateLimit(operationKey)) {
      console.log('Rate limited: getUserProjects');
      
      // If we have cache, return it even if it's stale
      if (this.projectsCache) {
        console.log('Returning stale projects cache due to rate limiting');
        return this.projectsCache;
      }
      
      throw new Error('Please wait a moment before fetching projects again');
    }
    
    try {
      console.log('ProjectService: Fetching user projects from API');
      
      // Use the helper method for the URL
      const url = this.getFullApiUrl('/api/projects/user-projects.php');
      
      // Use improved fetch logic with detailed error handling
      const response = await apiClient.get(
        url,
        {}, // No query params
        {}, // Default headers
        true, // Use authentication
        { forceCacheBust: forceRefresh } // Only cache bust if forced
      );
      
      // Update last fetch time
      this.lastFetchTime = now;
      
      if (response && response.status === 'success') {
        // Ensure we have a valid projects array
        if (!Array.isArray(response.projects)) {
          console.error('API returned success but no projects array');
          throw new Error('Projects data not found in API response');
        }
        
        // Process the projects to ensure consistent structure
        const processedProjects = response.projects.map(project => {
          // Ensure each project has required fields
          return {
            id: project.id || project.project_id,
            name: project.name || project.project_name || 'Unnamed Project',
            date: project.created_at || project.date || new Date().toISOString(),
            lastUpdated: project.updated_at || project.lastUpdated || project.date || new Date().toISOString(),
            items: project.items || project.item_count || 0,
            value: project.value || project.total_value || 0,
            status: project.status || 'Saved'
          };
        });
        
        // Sort projects by lastUpdated (newest first)
        processedProjects.sort((a, b) => {
          return new Date(b.lastUpdated) - new Date(a.lastUpdated);
        });
        
        // Update the cache
        this.projectsCache = processedProjects;
        
        console.log(`Fetched ${processedProjects.length} projects`);
        return processedProjects;
      }
      
      // If we got a non-success response
      console.error('Failed to get user projects, API returned:', response);
      throw new Error(response?.message || 'Failed to get user projects');
    } catch (error) {
      console.error('Error getting user projects:', error);
      
      // On error, try to return stale cache
      if (this.projectsCache) {
        console.log('Returning stale projects cache due to fetch error');
        return this.projectsCache;
      }
      
      throw error;
    }
  }
  
  /**
   * Delete a project
   * @param {string|number} projectId - ID of the project to delete
   * @returns {Promise<object>} Response with delete status
   */
  static async deleteProject(projectId) {
    // Check authentication first
    this.validateAuth();
    
    try {
      console.log(`ProjectService: Deleting project ${projectId}`);
      
      // Use the helper method for the delete URL
      const deleteUrl = this.getFullApiUrl('/api/projects/delete-project.php');
      
      const response = await apiClient.delete(deleteUrl, {
        projectId: projectId
      });
      
      // Immediately invalidate cache after deleting a project
      this.invalidateCache();
      
      console.log('ProjectService: Project deleted successfully');
      return response;
    } catch (error) {
      console.error('Error deleting project:', error);
      throw error;
    }
  }
  
  /**
   * Invalidate the cache for projects
   */
  static invalidateCache() {
    console.log('Invalidating projects cache - simplified version');
    
    // Reset internal cache variables (this always works)
    this.projectsCache = null;
    this.lastFetchTime = 0;
    
    try {
      // Only try to access _safeCache if it exists
      if (typeof this._safeCache === 'object' && this._safeCache !== null) {
        if (typeof this._safeCache.remove === 'function') {
          this._safeCache.remove('user-projects');
        }
      }
    } catch (e) {
      console.warn('Cache clearing error (non-critical):', e.message);
    }
  }
}