/**
 * Centralized error handling utility
 * Provides consistent error handling, logging, and user feedback
 */

import { DEBUG } from '../config';

// Error types for better categorization
export const ErrorTypes = {
  NETWORK: 'NETWORK_ERROR',
  AUTH: 'AUTHENTICATION_ERROR',
  API: 'API_ERROR',
  VALIDATION: 'VALIDATION_ERROR',
  PERMISSION: 'PERMISSION_ERROR',
  NOT_FOUND: 'NOT_FOUND_ERROR',
  SERVER: 'SERVER_ERROR',
  CLIENT: 'CLIENT_ERROR',
  UNKNOWN: 'UNKNOWN_ERROR'
};

// Error severity levels
export const ErrorSeverity = {
  CRITICAL: 'CRITICAL',   // Application cannot continue
  ERROR: 'ERROR',         // Feature is broken but app can continue
  WARNING: 'WARNING',     // Something is wrong but not breaking
  INFO: 'INFO'            // Informational only
};

/**
 * Custom application error class with enhanced properties
 */
export class AppError extends Error {
  constructor(message, options = {}) {
    super(message);
    this.name = options.name || 'AppError';
    this.type = options.type || ErrorTypes.UNKNOWN;
    this.severity = options.severity || ErrorSeverity.ERROR;
    this.status = options.status || null;
    this.originalError = options.originalError || null;
    this.metadata = options.metadata || {};
    this.userMessage = options.userMessage || message;
    this.recoverable = options.recoverable !== undefined ? options.recoverable : true;
    this.timestamp = new Date().toISOString();
  }
}

/**
 * Central error handler class
 */
export class ErrorHandler {
  static errorListeners = [];
  static lastErrors = [];
  static MAX_ERROR_HISTORY = 10;
  
  /**
   * Handle an error through the centralized system
   * @param {Error|AppError} error - The error to handle
   * @param {Object} options - Additional options
   * @returns {AppError} - The processed error
   */
  static handleError(error, options = {}) {
    // Convert to AppError if it's not already
    const appError = error instanceof AppError 
      ? error 
      : this.createAppError(error, options);
    
    // Log the error
    this.logError(appError);
    
    // Store in error history
    this.lastErrors.unshift(appError);
    this.lastErrors = this.lastErrors.slice(0, this.MAX_ERROR_HISTORY);
    
    // Notify all listeners
    this.notifyListeners(appError);
    
    return appError;
  }
  
  /**
   * Convert a standard error to an AppError
   * @param {Error} error - The original error
   * @param {Object} options - Additional options
   * @returns {AppError} - The converted error
   */
  static createAppError(error, options = {}) {
    // Default message
    let message = error.message || 'An unknown error occurred';
    let type = ErrorTypes.UNKNOWN;
    let severity = ErrorSeverity.ERROR;
    let status = error.status || null;
    let userMessage = 'Something went wrong. Please try again.';
    let recoverable = true;
    
    // Determine error type and severity based on the error
    if (error.isNetworkError || 
        (error.message && (
          error.message.includes('Failed to fetch') || 
          error.message.includes('NetworkError') ||
          error.message.includes('Network request failed')
        ))) {
      type = ErrorTypes.NETWORK;
      userMessage = 'Unable to connect to the server. Please check your internet connection.';
    } else if (error.status === 401 || 
               (error.message && error.message.toLowerCase().includes('auth'))) {
      type = ErrorTypes.AUTH;
      severity = ErrorSeverity.ERROR;
      userMessage = 'Your session has expired. Please log in again.';
    } else if (error.status === 403) {
      type = ErrorTypes.PERMISSION;
      userMessage = 'You don\'t have permission to access this resource.';
    } else if (error.status === 404) {
      type = ErrorTypes.NOT_FOUND;
      userMessage = 'The requested resource was not found.';
    } else if (error.status >= 400 && error.status < 500) {
      type = ErrorTypes.CLIENT;
      userMessage = 'There was a problem with your request. Please try again.';
    } else if (error.status >= 500) {
      type = ErrorTypes.SERVER;
      severity = ErrorSeverity.ERROR;
      userMessage = 'The server encountered an error. Please try again later.';
    }
    
    // Create the AppError
    return new AppError(message, {
      type,
      severity,
      status,
      userMessage: options.userMessage || userMessage,
      originalError: error,
      metadata: options.metadata || {},
      recoverable: options.recoverable !== undefined ? options.recoverable : recoverable
    });
  }
  
  /**
   * Log an error with appropriate formatting
   * @param {AppError} error - The error to log
   */
  static logError(error) {
    if (DEBUG.DISABLE_ERROR_LOGGING) return;
    
    const metadata = {
      type: error.type,
      severity: error.severity,
      status: error.status,
      timestamp: error.timestamp,
      ...error.metadata
    };
    
    console.group(`🔴 ${error.type}: ${error.message}`);
    console.error(error);
    console.log('Error metadata:', metadata);
    if (error.originalError && error.originalError !== error) {
      console.log('Original error:', error.originalError);
    }
    console.groupEnd();
    
    // In development, log the stack trace
    if (DEBUG.VERBOSE_ERROR_LOGGING && error.stack) {
      console.log('Stack trace:', error.stack);
    }
    
    // TODO: Add remote error logging service integration here
  }
  
  /**
   * Add an error listener
   * @param {Function} listener - Function to call when an error occurs
   */
  static addListener(listener) {
    if (typeof listener === 'function' && !this.errorListeners.includes(listener)) {
      this.errorListeners.push(listener);
    }
  }
  
  /**
   * Remove an error listener
   * @param {Function} listener - The listener to remove
   */
  static removeListener(listener) {
    this.errorListeners = this.errorListeners.filter(l => l !== listener);
  }
  
  /**
   * Notify all listeners of an error
   * @param {AppError} error - The error that occurred
   */
  static notifyListeners(error) {
    this.errorListeners.forEach(listener => {
      try {
        listener(error);
      } catch (listenerError) {
        console.error('Error in error listener:', listenerError);
      }
    });
  }
  
  /**
   * Get error history
   * @returns {Array<AppError>} - Recent errors
   */
  static getErrorHistory() {
    return [...this.lastErrors];
  }
  
  /**
   * Clear error history
   */
  static clearErrorHistory() {
    this.lastErrors = [];
  }
}

/**
 * Create a network error
 * @param {string} message - Error message
 * @param {Object} options - Additional options
 * @returns {AppError} - The network error
 */
export function createNetworkError(message, options = {}) {
  return new AppError(message, {
    type: ErrorTypes.NETWORK,
    severity: ErrorSeverity.ERROR,
    userMessage: options.userMessage || 'Unable to connect to the server. Please check your internet connection.',
    ...options
  });
}

/**
 * Create an authentication error
 * @param {string} message - Error message
 * @param {Object} options - Additional options
 * @returns {AppError} - The auth error
 */
export function createAuthError(message, options = {}) {
  return new AppError(message, {
    type: ErrorTypes.AUTH,
    severity: ErrorSeverity.ERROR,
    userMessage: options.userMessage || 'Authentication failed. Please log in again.',
    ...options
  });
}

/**
 * Create a validation error
 * @param {string} message - Error message
 * @param {Object} options - Additional options
 * @returns {AppError} - The validation error
 */
export function createValidationError(message, options = {}) {
  return new AppError(message, {
    type: ErrorTypes.VALIDATION,
    severity: ErrorSeverity.WARNING,
    userMessage: options.userMessage || 'Please check your input and try again.',
    ...options
  });
}

/**
 * Async function wrapper to catch and handle errors
 * @param {Function} fn - The async function to wrap
 * @param {Object} options - Error handling options
 * @returns {Function} - Wrapped function
 */
export function withErrorHandling(fn, options = {}) {
  return async (...args) => {
    try {
      return await fn(...args);
    } catch (error) {
      return ErrorHandler.handleError(error, options);
    }
  };
} 