/**
 * Enhanced API utilities using Lodash for optimal request handling
 */
import _ from 'lodash';
import { API_URL } from '../config';

// Store for in-flight requests to prevent duplicates
const pendingRequests = new Map();

// Store timestamps for rate limiting
const requestTimestamps = [];

// Configuration
const API_CONFIG = {
  MAX_REQUESTS_PER_MINUTE: 60, // Reduced to be more conservative
  THROTTLE_WINDOW_MS: 60000, // 1 minute window for rate limiting
  DEFAULT_CACHE_TTL: 120000, // Increased to 2 minutes for better caching
  MAX_RETRIES: 3,
  RETRY_DELAY: 2000, // Base retry delay (will increase with backoff)
  // Track which endpoints have hit rate limits
  RATE_LIMITED_ENDPOINTS: new Set(),
  // Track endpoints with errors
  ERROR_ENDPOINTS: new Map(),
  // Maximum number of consecutive errors before backing off
  MAX_CONSECUTIVE_ERRORS: 3,
  // Backoff time for endpoints with errors (ms)
  ERROR_BACKOFF_TIME: 30000
};

// Simple memory cache
const apiCache = new Map();

/**
 * Checks if a request would exceed rate limits
 * @returns {Object} Status object with blocked flag and retry delay
 */
const checkRateLimit = (url) => {
  // First, check if this specific endpoint is already rate limited
  const endpoint = new URL(url).pathname.split('/').pop();
  if (API_CONFIG.RATE_LIMITED_ENDPOINTS.has(endpoint)) {
    return {
      blocked: true,
      retryAfter: 5000, // Fixed delay for already rate-limited endpoints
      reason: `Endpoint ${endpoint} is currently rate limited`
    };
  }
  
  // Check if this endpoint has had consecutive errors
  if (API_CONFIG.ERROR_ENDPOINTS.has(endpoint)) {
    const { count, timestamp } = API_CONFIG.ERROR_ENDPOINTS.get(endpoint);
    const now = Date.now();
    
    // If we've had too many errors and we're still in the backoff period
    if (count >= API_CONFIG.MAX_CONSECUTIVE_ERRORS && 
        now - timestamp < API_CONFIG.ERROR_BACKOFF_TIME) {
      return {
        blocked: true,
        retryAfter: API_CONFIG.ERROR_BACKOFF_TIME - (now - timestamp),
        reason: `Endpoint ${endpoint} is blocked due to consecutive errors`
      };
    }
  }
  
  // Clean old timestamps outside the window
  const now = Date.now();
  const windowStart = now - API_CONFIG.THROTTLE_WINDOW_MS;
  
  // Remove timestamps outside the current window
  while (requestTimestamps.length > 0 && requestTimestamps[0] < windowStart) {
    requestTimestamps.shift();
  }
  
  // Check if we're over the limit
  if (requestTimestamps.length >= API_CONFIG.MAX_REQUESTS_PER_MINUTE) {
    const oldestTimestamp = requestTimestamps[0];
    const retryAfter = Math.max(100, oldestTimestamp + API_CONFIG.THROTTLE_WINDOW_MS - now);
    
    // Mark this endpoint as rate limited
    API_CONFIG.RATE_LIMITED_ENDPOINTS.add(endpoint);
    
    // Set a timeout to remove it from the rate limited set
    setTimeout(() => {
      API_CONFIG.RATE_LIMITED_ENDPOINTS.delete(endpoint);
      console.log(`Endpoint ${endpoint} removed from rate limiting`);
    }, retryAfter + 1000); // Add 1 second buffer
    
    return {
      blocked: true,
      retryAfter,
      reason: `Rate limit exceeded: ${requestTimestamps.length} requests in the last minute`
    };
  }
  
  return { blocked: false };
};

/**
 * Track an API call for rate limiting
 */
const trackApiCall = (url, success = true) => {
  requestTimestamps.push(Date.now());
  // Sort to ensure oldest is first
  requestTimestamps.sort((a, b) => a - b);
  
  // If the call was not successful, track the error
  if (!success) {
    const endpoint = new URL(url).pathname.split('/').pop();
    const now = Date.now();
    
    if (API_CONFIG.ERROR_ENDPOINTS.has(endpoint)) {
      const { count } = API_CONFIG.ERROR_ENDPOINTS.get(endpoint);
      API_CONFIG.ERROR_ENDPOINTS.set(endpoint, { count: count + 1, timestamp: now });
    } else {
      API_CONFIG.ERROR_ENDPOINTS.set(endpoint, { count: 1, timestamp: now });
    }
    
    // If we've reached the error threshold, log a warning
    const currentCount = API_CONFIG.ERROR_ENDPOINTS.get(endpoint).count;
    if (currentCount >= API_CONFIG.MAX_CONSECUTIVE_ERRORS) {
      console.warn(`Endpoint ${endpoint} has had ${currentCount} consecutive errors. Backing off for ${API_CONFIG.ERROR_BACKOFF_TIME}ms`);
    }
  } else {
    // If the call was successful, reset the error count
    const endpoint = new URL(url).pathname.split('/').pop();
    if (API_CONFIG.ERROR_ENDPOINTS.has(endpoint)) {
      API_CONFIG.ERROR_ENDPOINTS.delete(endpoint);
    }
  }
};

/**
 * Resets rate limiting for testing/emergency
 */
export const resetRateLimiting = () => {
  requestTimestamps.length = 0;
  API_CONFIG.RATE_LIMITED_ENDPOINTS.clear();
  console.log('Rate limiting has been reset');
};

/**
 * Get cache key from request details
 */
const getCacheKey = (url, options = {}) => {
  // For GET requests, use the URL as the key
  if (!options.method || options.method === 'GET') {
    return url;
  }
  
  // For other methods, include the method and request body in the key
  return `${options.method}:${url}:${JSON.stringify(options.body || {})}`;
};

/**
 * Gets the authentication token from localStorage
 * @returns {string|null} The authentication token or null if not found
 */
const getAuthToken = () => {
  try {
    const token = localStorage.getItem('token');
    
    // Additional debugging - log token fetch attempts
    console.debug('Token fetch attempt, token exists:', !!token);
    
    if (!token) {
      console.error('No token found in localStorage');
      return null;
    }
    
    // Always ensure token has Bearer prefix (production fix)
    if (!token.startsWith('Bearer ')) {
      console.log('Token format fixed: adding Bearer prefix');
      return `Bearer ${token}`;
    }
    
    return token;
  } catch (error) {
    console.error('Error retrieving auth token:', error);
    return null;
  }
};

/**
 * Adds authentication headers to options if needed
 * @param {Object} options - Request options
 * @param {boolean} authenticate - Whether to add auth token
 * @returns {Object} Updated options with auth headers
 */
const addAuthHeaders = (options, authenticate = true) => {
  if (!authenticate) return options;
  
  const token = getAuthToken();
  if (!token) {
    console.error('Authentication token not found in localStorage');
    // Don't throw here, allow the request to proceed without auth
    // It will fail properly on the server side
    return {
      ...options,
      headers: {
        ...options.headers,
        'Content-Type': 'application/json',
      }
    };
  }
  
  // Log authentication headers being added (helpful for debugging)
  if (process.env.NODE_ENV !== 'production') {
    console.debug('Adding auth header with token type:', token.startsWith('Bearer ') ? 'Bearer token' : 'Non-Bearer token');
  }
  
  return {
    ...options,
    headers: {
      ...options.headers,
      'Authorization': token,
      'Content-Type': 'application/json',
    }
  };
};

/**
 * Memoized GET request - caches identical GET requests
 */
export const memoizedGet = _.memoize(
  async (url, options = {}) => {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }
    return response.json();
  },
  // Use URL as the cache key
  (url) => url
);

/**
 * Throttled API call - limits how often a specific API can be called
 */
export const throttledApiCall = _.throttle(
  async (url, options = {}) => {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }
    return response.json();
  },
  1000, // Limit to one call per second
  { leading: true, trailing: false }
);

/**
 * Debounced API call - waits until calls stop coming in
 * Useful for search inputs, etc.
 */
export const debouncedApiCall = _.debounce(
  async (url, options = {}) => {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }
    return response.json();
  }, 
  300, // Wait 300ms after last call
  { leading: false, trailing: true, maxWait: 1000 }
);

/**
 * Make a general HTTP request with caching, deduplication and error handling
 * @param {string} url - The URL to request
 * @param {Object} options - Request options
 * @param {number} retryCount - Current retry count (internal use)
 * @returns {Promise<any>} Response data
 */
export const makeRequest = async (url, options = {}, retryCount = 0) => {
  const isGetRequest = !options.method || options.method === 'GET';
  const shouldCache = isGetRequest && !options.noCache;
  
  // Generate cache key
  const cacheKey = getCacheKey(url, options);
  
  // Skip cache for certain critical endpoints that need fresh data
  const isCriticalEndpoint = url.includes('user-projects.php') || 
                             url.includes('verify-token.php') ||
                             url.includes('login.php');
  
  const forceCacheBust = options.forceCacheBust || 
                          (isCriticalEndpoint && retryCount > 0); // Bust cache on retries for critical endpoints
  
  // Check if request is already in progress (deduplication)
  const pendingRequest = pendingRequests.get(cacheKey);
  if (pendingRequest && !forceCacheBust) {
    console.log(`Cache hit for in-flight request to ${url}`);
    return pendingRequest;
  }
  
  // Check for cached response
  if (shouldCache && !forceCacheBust) {
    const cached = apiCache.get(cacheKey);
    if (cached && Date.now() - cached.timestamp < cached.ttl) {
      console.log(`Cache hit for ${url}`);
      return cached.data;
    }
  }
  
  // Check rate limiting before making the request
  const rateLimitCheck = checkRateLimit(url);
  if (rateLimitCheck.blocked) {
    console.warn(`API call to ${url} blocked: ${rateLimitCheck.reason}`);
    
    // If we have a cached response, return it even if expired
    const staleCache = apiCache.get(cacheKey);
    if (staleCache) {
      console.log(`Returning stale cache for rate-limited request to ${url}`);
      return staleCache.data;
    }
    
    throw new Error(`API call blocked due to excessive requests`);
  }
  
  // Track this request for rate limiting
  trackApiCall(url);
  
  // Create the actual request promise
  const requestPromise = (async () => {
    try {
      // Make the actual fetch request
      const response = await fetch(url, options);
      
      // Check for HTTP errors
      if (!response.ok) {
        // Track this as a failed request
        trackApiCall(url, false);
        
        // Handle specific HTTP status codes
        if (response.status === 429) {
          throw new Error('Rate limit exceeded');
        } else if (response.status === 401 || response.status === 403) {
          throw new Error('Authentication failed');
        } else if (response.status >= 500) {
          throw new Error(`Server error: ${response.status}`);
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      
      // Parse the JSON response
      const data = await response.json();
      
      // Track this as a successful request
      trackApiCall(url, true);
      
      // Cache successful responses
      if (shouldCache) {
        const ttl = options.cacheTTL || API_CONFIG.DEFAULT_CACHE_TTL;
        apiCache.set(cacheKey, {
          data,
          timestamp: Date.now(),
          ttl
        });
      }
      
      return data;
    } catch (error) {
      // Track this as a failed request if it's not already tracked
      trackApiCall(url, false);
      
      // Handle retries for network errors or server errors
      const isNetworkError = error.message.includes('network') || 
                             error.message.includes('fetch') ||
                             error.message.includes('Server error');
      
      if (isNetworkError && retryCount < API_CONFIG.MAX_RETRIES) {
        console.log(`Retry ${retryCount + 1}/${API_CONFIG.MAX_RETRIES} for ${url}`);
        
        // Exponential backoff
        const delay = API_CONFIG.RETRY_DELAY * Math.pow(2, retryCount);
        await new Promise(resolve => setTimeout(resolve, delay));
        
        // Remove from pending requests before retry
        pendingRequests.delete(cacheKey);
        
        // Retry the request
        return makeRequest(url, options, retryCount + 1);
      }
      
      // If we have a cached response, return it on error
      const staleCache = apiCache.get(cacheKey);
      if (staleCache) {
        console.log(`Returning stale cache due to error for ${url}`);
        return staleCache.data;
      }
      
      // Rethrow the error if we can't recover
      throw error;
    } finally {
      // Clean up the pending request
      pendingRequests.delete(cacheKey);
    }
  })();
  
  // Store the promise for deduplication
  pendingRequests.set(cacheKey, requestPromise);
  
  return requestPromise;
};

/**
 * Perform a GET request
 * @param {string} url - The URL to request
 * @param {Object} queryParams - Query parameters
 * @param {Object} headers - Custom headers
 * @param {boolean} authenticate - Whether to add auth headers
 * @param {Object} options - Additional options
 * @returns {Promise<any>} Response data
 */
export const get = (url, queryParams = {}, headers = {}, authenticate = true, options = {}) => {
  // Build full URL with query params
  let fullUrl = url;
  
  // If URL is already absolute or starts with /api/, don't modify it
  if (!url.startsWith('http') && !url.startsWith('/api/')) {
    fullUrl = `${API_URL}${url}`;
  }
  
  if (!_.isEmpty(queryParams)) {
    // Convert queryParams to URLSearchParams
    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        searchParams.append(key, value);
      }
    });
    
    // Append to URL
    const queryString = searchParams.toString();
    if (queryString) {
      fullUrl += (fullUrl.includes('?') ? '&' : '?') + queryString;
    }
  }
  
  // Build request options
  let requestOptions = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      ...headers
    },
    ...options
  };
  
  // Add auth headers if needed
  if (authenticate) {
    requestOptions = addAuthHeaders(requestOptions);
  }
  
  return makeRequest(fullUrl, requestOptions);
};

/**
 * POST request
 */
export const post = async (url, data, options = {}, authenticate = true) => {
  // Fix URL construction: ensure URL has a proper protocol
  let fullUrl = url;
  
  // If URL is relative (doesn't start with http or https)
  if (!url.startsWith('http')) {
    // If it starts with /api/, it's already a relative path
    if (url.startsWith('/api/')) {
      fullUrl = url;
    } else {
      // Otherwise, prepend the API_URL
      fullUrl = `${API_URL}${url}`;
    }
  }
  
  const requestOptions = authenticate ? addAuthHeaders({
    ...options,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...options.headers
    },
    body: JSON.stringify(data)
  }) : {
    ...options,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...options.headers
    },
    body: JSON.stringify(data)
  };
  
  return makeRequest(fullUrl, requestOptions);
};

/**
 * PUT request
 */
export const put = async (url, data, options = {}, authenticate = true) => {
  // Fix URL construction: if url already starts with http or /api/, don't append API_URL
  let fullUrl = url;
  if (!url.startsWith('http') && !url.startsWith('/api/')) {
    fullUrl = `${API_URL}${url}`;
  }
  
  const requestOptions = authenticate ? addAuthHeaders({
    ...options,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...options.headers
    },
    body: JSON.stringify(data)
  }) : {
    ...options,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...options.headers
    },
    body: JSON.stringify(data)
  };
  
  return makeRequest(fullUrl, requestOptions);
};

/**
 * DELETE request
 */
export const del = async (url, data = null, options = {}, authenticate = true) => {
  // Fix URL construction: if url already starts with http or /api/, don't append API_URL
  let fullUrl = url;
  if (!url.startsWith('http') && !url.startsWith('/api/')) {
    fullUrl = `${API_URL}${url}`;
  }
  
  let requestOptions = {
    ...options,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...options.headers
    }
  };
  
  if (data) {
    requestOptions.body = JSON.stringify(data);
  }
  
  if (authenticate) {
    requestOptions = addAuthHeaders(requestOptions);
  }
  
  return makeRequest(fullUrl, requestOptions);
};

// Convenience API client object
export const apiClient = {
  get,
  post,
  put,
  delete: del,
  makeRequest,
  resetRateLimiting
}; 