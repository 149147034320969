// src/context/ToastContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Toast from '../components/Toast';
import { ErrorHandler, ErrorTypes, AppError } from '../utils/errorHandler';

const ToastContext = createContext(null);

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  // Register with error handler to show toasts for errors
  useEffect(() => {
    const errorListener = (error) => {
      // Only show toast for errors that have user messages
      if (error.userMessage) {
        addToast({
          id: uuidv4(),
          type: error.type || 'error',
          message: error.userMessage,
          duration: error.severity === 'CRITICAL' ? 8000 : 5000,
          autoClose: error.severity !== 'CRITICAL'
        });
      }
    };
    
    // Register the listener
    ErrorHandler.addListener(errorListener);
    
    // Clean up on unmount
    return () => {
      ErrorHandler.removeListener(errorListener);
    };
  }, []);

  const addToast = (toast) => {
    setToasts(prev => [...prev, toast]);
  };

  const removeToast = (id) => {
    setToasts(prev => prev.filter(toast => toast.id !== id));
  };

  const showSuccess = (message, options = {}) => {
    const id = uuidv4();
    addToast({
      id,
      type: 'success',
      message,
      duration: options.duration || 3000,
      autoClose: options.autoClose !== false
    });
    return id;
  };

  const showError = (message, options = {}) => {
    const id = uuidv4();
    
    // If message is an Error or AppError, use it directly
    if (message instanceof Error) {
      const error = message instanceof AppError 
        ? message 
        : ErrorHandler.createAppError(message, options);
      
      addToast({
        id,
        type: error.type || 'error',
        message: error.userMessage || error.message,
        duration: options.duration || 5000,
        autoClose: options.autoClose !== false
      });
    } else {
      // Otherwise, just use the message string
      addToast({
        id,
        type: options.type || 'error',
        message,
        duration: options.duration || 5000,
        autoClose: options.autoClose !== false
      });
    }
    
    return id;
  };

  const showWarning = (message, options = {}) => {
    const id = uuidv4();
    addToast({
      id,
      type: 'warning',
      message,
      duration: options.duration || 4000,
      autoClose: options.autoClose !== false
    });
    return id;
  };

  const showInfo = (message, options = {}) => {
    const id = uuidv4();
    addToast({
      id,
      type: 'info',
      message,
      duration: options.duration || 3000,
      autoClose: options.autoClose !== false
    });
    return id;
  };

  const clearToasts = () => {
    setToasts([]);
  };

  return (
    <ToastContext.Provider value={{ 
      showSuccess, 
      showError, 
      showWarning, 
      showInfo, 
      clearToasts,
      removeToast
    }}>
      {children}
      <div className="toast-container">
        {toasts.map(toast => (
          <Toast
            key={toast.id}
            message={toast.message}
            type={toast.type}
            duration={toast.duration}
            autoClose={toast.autoClose}
            onClose={() => removeToast(toast.id)}
          />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};