/**
 * Authentication Debug Utility
 * This file contains utilities to help debug authentication issues
 */

// Check and log token details
export const debugToken = () => {
  try {
    const token = localStorage.getItem('token');
    console.group('🔐 Auth Token Debug');
    console.log('Token exists:', !!token);
    
    if (token) {
      console.log('Token length:', token.length);
      console.log('Token format:', token.startsWith('Bearer ') ? 'Has Bearer prefix' : 'Missing Bearer prefix');
      
      // Check if token is in the right format (starts with Bearer)
      if (!token.startsWith('Bearer ')) {
        console.warn('⚠️ Token is missing the Bearer prefix, this will cause auth failures');
      }
      
      // Try to decode token to check structure (if JWT)
      try {
        const parts = token.replace('Bearer ', '').split('.');
        if (parts.length === 3) {
          console.log('Appears to be a JWT token with 3 parts');
        } else {
          console.log('Not a standard JWT token format');
        }
      } catch (e) {
        console.log('Not a JWT token or cannot be parsed');
      }
    } else {
      console.warn('⚠️ No auth token found in localStorage!');
    }

    // Check if user data exists
    const userData = localStorage.getItem('user');
    console.log('User data exists:', !!userData);
    
    if (userData) {
      try {
        const user = JSON.parse(userData);
        console.log('User ID:', user.id || 'No ID found');
        console.log('Username:', user.name || user.username || 'No name found');
      } catch (e) {
        console.error('Failed to parse user data:', e);
      }
    }
    
    console.groupEnd();
    return { hasToken: !!token, hasUser: !!userData };
  } catch (error) {
    console.error('Error in debugToken:', error);
    return { hasToken: false, hasUser: false, error: error.message };
  }
};

// Try to fix token issues automatically
export const attemptTokenRepair = () => {
  try {
    const token = localStorage.getItem('token');
    
    // If no token, can't repair
    if (!token) {
      console.warn('Cannot repair - no token found');
      return false;
    }
    
    // If token doesn't start with Bearer, add it
    if (!token.startsWith('Bearer ')) {
      const fixedToken = `Bearer ${token}`;
      localStorage.setItem('token', fixedToken);
      console.log('Token repaired: Added Bearer prefix');
      return true;
    }
    
    return true;
  } catch (error) {
    console.error('Error repairing token:', error);
    return false;
  }
}; 