/* eslint-disable no-restricted-globals */
// This service worker file provides enhanced caching for API requests
// to prevent excessive API calls and provide offline support

const CACHE_NAME = 'contents-team-cache-v1';
const API_CACHE_DURATION = 5 * 60 * 1000; // 5 minutes for API responses

const API_ENDPOINTS = [
  '/api/projects/user-projects.php',
  '/api/projects/project.php',
];

// Install event - precache essential resources
self.addEventListener('install', (event) => {
  console.log('Service Worker installing');
  event.waitUntil(
    caches.open(CACHE_NAME).then((cache) => {
      // Precache static assets here
      return cache.addAll([
        '/',
        '/index.html',
        '/static/js/main.chunk.js',
        '/static/js/vendors~main.chunk.js',
        '/static/js/bundle.js',
        '/static/css/main.chunk.css'
      ]);
    })
  );
});

// Activate event - cleanup old caches
self.addEventListener('activate', (event) => {
  console.log('Service Worker activating');
  event.waitUntil(
    caches.keys().then((cacheNames) => {
      return Promise.all(
        cacheNames.map((cacheName) => {
          if (cacheName !== CACHE_NAME) {
            console.log('Deleting old cache:', cacheName);
            return caches.delete(cacheName);
          }
        })
      );
    })
  );
});

// Fetch event - network-first strategy with cache fallback for API endpoints
self.addEventListener('fetch', (event) => {
  const url = new URL(event.request.url);
  
  // Special handling for API endpoints
  const isApiRequest = API_ENDPOINTS.some(endpoint => url.pathname.includes(endpoint));
  
  if (isApiRequest) {
    // Network-first strategy for API endpoints with caching
    event.respondWith(
      handleApiRequest(event.request)
    );
  } else {
    // Cache-first for static assets, network fallback
    event.respondWith(
      caches.match(event.request).then((cachedResponse) => {
        if (cachedResponse) {
          return cachedResponse;
        }
        return fetch(event.request).then((response) => {
          // Don't cache non-successful responses
          if (!response || response.status !== 200) {
            return response;
          }
          
          // Clone the response because we need to use it twice
          let responseToCache = response.clone();
          
          caches.open(CACHE_NAME).then((cache) => {
            cache.put(event.request, responseToCache);
          });
          
          return response;
        });
      })
    );
  }
});

// Special handling for API requests
async function handleApiRequest(request) {
  // Try network first
  try {
    const response = await fetch(request);
    
    // If successful, clone and cache
    if (response && response.status === 200) {
      const responseToCache = response.clone();
      
      // Cache with timestamp metadata
      const cache = await caches.open(CACHE_NAME);
      const headers = new Headers(responseToCache.headers);
      headers.append('x-cached-at', Date.now().toString());
      
      const cachedResponse = new Response(await responseToCache.blob(), {
        status: responseToCache.status,
        statusText: responseToCache.statusText,
        headers: headers
      });
      
      cache.put(request, cachedResponse);
    }
    
    return response;
  } catch (error) {
    console.log('Fetch failed, trying cache', error);
    
    // If network fails, try cache
    const cachedResponse = await caches.match(request);
    
    if (cachedResponse) {
      // Check if the cache is still valid
      const cachedAt = cachedResponse.headers.get('x-cached-at');
      
      if (cachedAt) {
        const cacheAge = Date.now() - parseInt(cachedAt);
        
        if (cacheAge < API_CACHE_DURATION) {
          // Cache is fresh enough
          return cachedResponse;
        } else {
          console.log('Cache expired, using stale data with warning');
          // Use stale cache with warning header
          const headers = new Headers(cachedResponse.headers);
          headers.append('x-stale-cache', 'true');
          
          return new Response(await cachedResponse.blob(), {
            status: cachedResponse.status,
            statusText: cachedResponse.statusText,
            headers: headers
          });
        }
      }
      
      return cachedResponse;
    }
    
    // If no cache, return error response
    return new Response(JSON.stringify({
      status: 'error',
      message: 'Network error and no cached data available'
    }), {
      status: 503,
      headers: { 'Content-Type': 'application/json' }
    });
  }
}

// Handle messages from client
self.addEventListener('message', (event) => {
  if (event.data.action === 'skipWaiting') {
    self.skipWaiting();
  }
  
  if (event.data.action === 'clearApiCache') {
    clearApiCache();
  }
});

// Clear API cache (used when data is known to be stale)
async function clearApiCache() {
  const cache = await caches.open(CACHE_NAME);
  const requests = await cache.keys();
  
  const apiRequests = requests.filter(request => {
    const url = new URL(request.url);
    return API_ENDPOINTS.some(endpoint => url.pathname.includes(endpoint));
  });
  
  await Promise.all(apiRequests.map(request => cache.delete(request)));
  console.log(`Cleared ${apiRequests.length} API cache entries`);
}

// This function is needed for compatibility with Create React App
export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used.
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      registerValidSW(swUrl, config);
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              console.log('New content is available and will be used when all tabs for this page are closed.');
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              console.log('Content is cached for offline use.');
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
} 