import React, { Component } from 'react';
import { debugToken, attemptTokenRepair } from '../utils/authDebug';
import { ErrorHandler, ErrorTypes, AppError } from '../utils/errorHandler';

/**
 * ErrorBoundary component for gracefully handling React errors
 * Includes mechanisms to recover from authentication errors
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null,
      errorInfo: null,
      isRecovering: false
    };
  }

  // Catch errors in any child components
  static getDerivedStateFromError(error) {
    // Convert to AppError if it's not already
    const appError = error instanceof AppError 
      ? error 
      : ErrorHandler.createAppError(error);
      
    return { hasError: true, error: appError };
  }

  componentDidMount() {
    // Register with the error handler system
    ErrorHandler.addListener(this.handleGlobalError);
  }
  
  componentWillUnmount() {
    // Unregister from the error handler system
    ErrorHandler.removeListener(this.handleGlobalError);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    
    // Process through the error handler
    const appError = ErrorHandler.handleError(error, {
      metadata: { componentStack: errorInfo?.componentStack }
    });
    
    // Check if it's an authentication error
    if (appError.type === ErrorTypes.AUTH) {
      console.log('Authentication error detected, will attempt recovery');
      this.attemptRecovery();
    }
  }
  
  // Handle errors from the global error system
  handleGlobalError = (error) => {
    // Only handle errors if this is the top-level error boundary
    if (this.props.isTopLevel && !this.state.hasError) {
      this.setState({ 
        hasError: true, 
        error,
        isRecovering: false
      });
      
      // Auto-attempt recovery for auth errors
      if (error.type === ErrorTypes.AUTH) {
        this.attemptRecovery();
      }
    }
  };
  
  // Try to recover from the error
  attemptRecovery = async () => {
    this.setState({ isRecovering: true });
    
    try {
      console.log('Attempting automatic error recovery...');
      
      // Debug the current token
      const { hasToken } = debugToken();
      
      // Try to repair the token if it exists
      if (hasToken) {
        const repaired = attemptTokenRepair();
        console.log('Token repair attempt:', repaired ? 'successful' : 'failed');
        
        // If we have an auth context, try to refresh
        if (this.props.refreshToken) {
          await this.props.refreshToken();
        }
        
        // Reset the error state after a brief delay
        setTimeout(() => {
          this.setState({ 
            hasError: false, 
            error: null, 
            errorInfo: null,
            isRecovering: false 
          });
        }, 1000);
        
        return;
      }
      
      // If no token, suggest login
      if (this.props.loginWithDemoCredentials) {
        console.log('No token found, will attempt demo login');
        const loginSuccess = await this.props.loginWithDemoCredentials();
        
        if (loginSuccess) {
          // Reset the error state after successful login
          this.setState({ 
            hasError: false, 
            error: null, 
            errorInfo: null,
            isRecovering: false 
          });
          return;
        }
      }
    } catch (e) {
      console.error('Recovery attempt failed:', e);
      // Process through error handler
      ErrorHandler.handleError(e, {
        userMessage: 'Recovery attempt failed. Please try logging in again.'
      });
    }
    
    this.setState({ isRecovering: false });
  };
  
  // Reset the error state
  resetError = () => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null
    });
  };
  
  render() {
    const { hasError, error, isRecovering } = this.state;
    const { fallback, children } = this.props;
    
    if (!hasError) {
      return children;
    }
    
    // Use custom fallback if provided
    if (fallback) {
      return fallback(error, this.resetError);
    }
    
    // Default error UI based on error type
    return (
      <div className="error-boundary" style={{
        margin: '2rem auto',
        maxWidth: '600px',
        padding: '2rem',
        borderRadius: '0.5rem',
        backgroundColor: '#fff3f3',
        border: '1px solid #ffcccc',
        boxShadow: '0 2px 6px rgba(0,0,0,0.1)'
      }}>
        <h2 style={{ color: '#d32f2f', marginTop: 0 }}>
          {error?.type === ErrorTypes.AUTH 
            ? 'Authentication Error' 
            : error?.type === ErrorTypes.NETWORK
              ? 'Network Error'
              : 'Something went wrong'
          }
        </h2>
        
        <p style={{ marginBottom: '1.5rem' }}>
          {error?.userMessage || 'An unexpected error occurred in the application.'}
        </p>
        
        {isRecovering ? (
          <div style={{ textAlign: 'center', padding: '1rem' }}>
            <p>Attempting to recover...</p>
          </div>
        ) : (
          <div>
            <button
              onClick={this.attemptRecovery}
              style={{
                padding: '0.75rem 1.5rem',
                backgroundColor: '#2196f3',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                fontSize: '1rem',
                cursor: 'pointer',
                marginRight: '1rem'
              }}
            >
              {error?.type === ErrorTypes.AUTH 
                ? 'Repair Authentication' 
                : error?.type === ErrorTypes.NETWORK
                  ? 'Retry Connection'
                  : 'Try Recovery'
              }
            </button>
            
            <button
              onClick={() => window.location.href = '/login'}
              style={{
                padding: '0.75rem 1.5rem',
                backgroundColor: '#f5f5f5',
                color: '#333',
                border: '1px solid #ccc',
                borderRadius: '4px',
                fontSize: '1rem',
                cursor: 'pointer'
              }}
            >
              Go to Login
            </button>
            
            {error?.type !== ErrorTypes.AUTH && (
              <button
                onClick={this.resetError}
                style={{
                  padding: '0.75rem 1.5rem',
                  backgroundColor: '#4caf50',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  fontSize: '1rem',
                  cursor: 'pointer',
                  marginLeft: '1rem'
                }}
              >
                Try Again
              </button>
            )}
          </div>
        )}
        
        {/* Show technical details in development mode */}
        {process.env.NODE_ENV === 'development' && (
          <div style={{ 
            marginTop: '2rem', 
            padding: '1rem', 
            backgroundColor: '#f5f5f5', 
            borderRadius: '4px',
            fontSize: '0.875rem'
          }}>
            <h4 style={{ marginTop: 0 }}>Technical Details (Development Only)</h4>
            <p><strong>Type:</strong> {error?.type}</p>
            <p><strong>Message:</strong> {error?.message}</p>
            {error?.status && <p><strong>Status:</strong> {error.status}</p>}
            {error?.metadata && Object.keys(error.metadata).length > 0 && (
              <div>
                <p><strong>Metadata:</strong></p>
                <pre>{JSON.stringify(error.metadata, null, 2)}</pre>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ErrorBoundary; 